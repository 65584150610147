import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function ScrollToHashElement() {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      // Remove the "#" from the hash and find the element

      const id = hash.replace('#', '')

      const element = document.getElementById(id)

      if (element) {
        // Scroll to the element

        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [hash]) // Dependency on hash

  return null // This component doesn't render anything itself
}
