import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import styles from '../index.module.scss'

export function Upsell() {
  const { data: currentUser } = useCurrentAccount()

  return (
    <section className={styles.try}>
      <h3>Try Zentask today for free</h3>
      <p>Say goodbye to chaos and hello to a harmonious work-life balance 🚀</p>

      {!currentUser && (
        <Link to="/login">
          <Button size={ButtonSizes.xl} type="button" label="Get started" />
        </Link>
      )}

      {currentUser && (
        <Link to="/chat/talk-to-zentask">
          <Button size={ButtonSizes.xl} type="button" label="Start chat" />
        </Link>
      )}

      <div className={styles.nocard}>
        <Icon name={IconNames.check} />
        No credit card required
      </div>
    </section>
  )
}
