import { useEffect, useState } from 'react'
import { apiClient } from 'src/api/api-client'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Loader } from 'src/core/ds/loader'
import { useChatStore } from 'src/entities/chat/chatStore'
import { useHistory } from 'src/entities/history/useHistory'
import { useSubscription } from 'src/entities/subscription/useSubscription'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { useDebounce } from 'src/hooks/useDebounce'
import { useMedia } from 'src/hooks/useMedia'
import { useUIStore } from 'src/services/store/UIStore'
import { HistoryItem } from './components/HistoryItem'
import styles from './index.module.scss'

export function HistorySearch({ isEnabled }) {
  const [searchInput, setSearchInput] = useState('')
  const mediaWidth = useMedia()
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [isSearchBlocked, setIsSearchBlocked] = useState(false)
  const [searchResults, setSearchResults] = useState<any>(null)
  const setIsOpenHistorySearch = useUIStore((state) => state.setIsOpenHistorySearch)
  const debouncedValue = useDebounce(searchInput, 500)

  const setSelectedHistoryItem = useChatStore((store) => store.setSelectedHistoryItem)
  const selectedHistoryItem = useChatStore((store) => store.selectedHistoryItem)
  const setCurrentContextId = useChatStore((store) => store.setCurrentContextId)
  const client = apiClient.history()

  const isMobile = mediaWidth < 768

  // eslint-disable-next-line
  const { data, isLoading, refetch, isRefetching } = useHistory({
    query: '',
    isEnabled,
  })

  const { tryUseFeature, isEligibleToUseFeature } = useSubscription()
  const isHistorySearchEligible = isEligibleToUseFeature('historySearch')

  const onReset = () => {
    setSearchInput('')
    setSearchResults(null)
  }

  useEffect(() => {
    if (searchInput.length > 1) {
      tryUseFeature({ featureName: 'historySearch', onFeatureBlocked: () => setIsSearchBlocked(true) })
    }
  }, [searchInput])

  useEffect(() => {
    if (debouncedValue.length < 3) {
      setSearchResults(null)

      return
    }

    if (!isHistorySearchEligible) return

    setIsLoadingSearch(true)

    client
      .searchByQuery(debouncedValue)
      .then((response: any) => {
        if (response?.length > 0) {
          setSearchResults(response)
        }

        if (response?.length === 0) {
          setSearchResults([])
        }

        setIsLoadingSearch(false)
      })
      .catch((err) => {
        setIsLoadingSearch(false)
        console.log('error', err)
      })
  }, [debouncedValue, client, isHistorySearchEligible])

  const isSearchView = debouncedValue.length > 2 && searchResults

  if (isLoading) {
    return (
      <div>
        <div className={styles.form}>
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Input
          icon={IconNames.search}
          type="text"
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search your conversation history"
          value={searchInput}
          label="Search conversation"
          tooltipProps={{ label: 'Feature is available only for premium users', showPremium: true }}
          autofocus
          isLoading={isLoadingSearch}
          isDisabled={isSearchBlocked}
          action={
            // eslint-disable-next-line
            searchInput.length > 2 ? (
              <Button
                onClick={onReset}
                variant={ButtonVariants.unstyled}
                iconColor="var(--color-secondary)"
                type="button"
                icon={IconNames.close}
              />
            ) : null
          }
        />
      </div>

      {!isSearchView && (
        <div className={styles.results}>
          <h2>
            Last conversations
            <Button
              isLoading={isRefetching}
              type="button"
              tooltip="Refetch history"
              onClick={refetch}
              variant={ButtonVariants.unstyled}
              icon={IconNames.refresh}
            />
          </h2>
          <div className={styles.resultsList}>
            {/* eslint-disable-next-line */}
            {((data as any[]) || []).map((item: any) => (
              <HistoryItem
                isSelected={item.id === selectedHistoryItem?.id}
                onSelect={(item) => {
                  setSelectedHistoryItem(item)
                  setCurrentContextId(item.id)
                  sendGAEvent('click_history_item')
                  isMobile && setIsOpenHistorySearch(false)
                }}
                key={item.id}
                item={item}
              />
            ))}
            {!data ? <div className={styles.empty}>No conversations yet</div> : null}
          </div>
        </div>
      )}

      {isSearchView && (
        <div className={styles.results}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <h2>Found results: {searchResults?.length}</h2>
          <div className={styles.resultsList}>
            {/* eslint-disable-next-line */}
            {((searchResults as any[]) || []).map((item: any) => (
              <HistoryItem
                onSelect={(item) => {
                  setSelectedHistoryItem(item)
                  setCurrentContextId(item.id)
                  sendGAEvent('click_history_item')
                  isMobile && setIsOpenHistorySearch(false)
                }}
                searchInput={debouncedValue}
                isSearchResult
                key={item.id}
                isSelected={item.id === selectedHistoryItem?.id}
                item={item}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
