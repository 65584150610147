/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link } from 'react-router-dom'
import { PinsSkeleton } from 'src/components/skeletons/PinsSkeleton'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { usePins } from 'src/entities/pins/usePins'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { useOutsideClick } from 'src/hooks/useOutsideClick'
import styles from './index.module.scss'

export function PinsList({ isLoadingCurrentAssitant, currentAssistant }) {
  const { changedBlockRef, isBlockActive, setIsBlockActive } = useOutsideClick(false)
  const { data: pinsList, isLoading: isLoadingPins } = usePins()

  if (isLoadingPins || isLoadingCurrentAssitant) {
    return <PinsSkeleton />
  }

  return (
    <div className={styles.select}>
      {!isBlockActive && (
        <div className={styles.selectedContainer} onClick={() => setIsBlockActive(true)}>
          <span>{currentAssistant?.title}</span>
          <span className={styles.icon}>
            <Icon color="var(--color-grey)" size={IconSize.xs} name={IconNames.shevronDown} />
          </span>
        </div>
      )}

      {isBlockActive && (
        <div className={styles.selectedContainer} onClick={() => setIsBlockActive(false)}>
          <span>{currentAssistant?.title}</span>
          <span className={styles.icon}>
            <Icon color="var(--color-grey)" size={IconSize.xs} name={IconNames.shevronDown} />
          </span>
        </div>
      )}

      {isBlockActive && (
        <div className={styles.dropdown} ref={changedBlockRef}>
          {pinsList?.map((option) => (
            <Link
              className={styles.pinItem}
              onClick={() => {
                setIsBlockActive(false)
                sendGAEvent('click_pinned_task')
              }}
              key={option.id}
              to={`/chat/${option.slug}`}
            >
              <div className={styles.title}>{option.title}</div>
              <div className={styles.description}>{option.description}</div>
              {option?.id === currentAssistant?.id && (
                <div className={styles.selected}>
                  <Icon color="var(--color-primary)" size={IconSize.xs} name={IconNames.check} />
                </div>
              )}
            </Link>
          ))}
          {!pinsList || pinsList?.length === 0 ? <div className={styles.empty}>No assistant pinned</div> : undefined}
        </div>
      )}
    </div>
  )
}
