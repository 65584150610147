import Pusher from 'pusher-js'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { apiClient } from 'src/api/api-client'
import { config } from 'src/config/config'
import { queryClient } from 'src/queries/reactQueryClient'
import { QUERY_KEY_CURRENT_ACCOUNT } from '../account/queryKeys'
import { useCurrentAccount } from '../account/useCurrentAccount'
import { useGetHistoryById } from '../history/useGetHistoryById'
import { useGetPromptBySlug } from '../prompts/useGetPromptBySlug'
import { useChatStore } from './chatStore'
import { aiResponseStringHandler, scrollToDiv } from './utils'

let temp = ''
const defaultChat = 'talk-to-zentask'

export const useAIChat = () => {
  const client = apiClient.tasks()
  const navigate = useNavigate()
  const { historyId, taskSlug } = useParams()

  const { data: historyElement } = useGetHistoryById({ historyId })
  const { data: currentTask, isLoading: isLoadingCurrentAssitant } = useGetPromptBySlug({
    slug: taskSlug || historyElement?.task?.slug || defaultChat,
  })
  const taskId = currentTask?.id || 'ZEN'

  const setCurrentContextId = useChatStore((store) => store.setCurrentContextId)
  const currentContextId = useChatStore((store) => store.currentContextId)
  const [pusher, setPusher] = useState<any>(null)
  const [channel, setChannel] = useState<any>(null)
  const [isConnected, setIsConnected] = useState(false)
  const [status, setStatus] = useState('idle')
  const [messages, setMessages] = useState<any>([])
  const [currentAiResponse, setCurrentAiResponse] = useState('')

  const { data: currentAccount } = useCurrentAccount()

  const resetChatMessages = () => {
    setMessages([])
    setCurrentContextId(null)
    const input = document.getElementById('textarea-id')

    if (input) {
      setTimeout(() => {
        input?.focus()
      })
    }

    if (historyId) {
      navigate(`/chat/${currentTask?.slug}`)
    }
  }

  useEffect(() => {
    if (taskId && !historyId) {
      setMessages([])
      setCurrentContextId(null)
    }
  }, [taskId, historyId, setMessages, setCurrentContextId])

  useEffect(() => {
    if (historyElement) {
      const messages: any = []
      historyElement?.context?.items?.forEach((el) => {
        messages.push({ type: 'user', content: el.input, id: el.id })
        messages.push({ type: 'ai', content: el.output, id: el.id })
      })
      setMessages(messages)
      // setSelectedTask(historyElement.task)
      setCurrentContextId(historyElement?.context_id)
    }
  }, [historyElement, historyElement?.id, setMessages])

  useEffect(() => {
    if (!currentAccount) return

    const newPusher = new Pusher(config.pusherKey, {
      wsHost: config.pusherHost,
      wsPort: config.pusherPort,
      forceTLS: config.pusherForceTSL,
      cluster: 'eu',
      httpHost: config.pusherHost,
      httpPath: '/',
    })

    newPusher.connection.bind('connected', () => {
      console.log('Connected to Pusher')
      setIsConnected(true)
      setStatus('idle')
    })

    newPusher.connection.bind('disconnected', () => {
      console.log('Disconnected from Pusher')
      setIsConnected(false)
      setStatus('idle')
    })

    setPusher(newPusher)

    // eslint-disable-next-line consistent-return
    return () => {
      newPusher.disconnect()
    }
  }, [currentAccount])

  useEffect(() => {
    if (!channel) return

    const handleResponse = (data) => {
      const parsedDataFromResponse = aiResponseStringHandler(data.response)
      temp += parsedDataFromResponse
      setCurrentAiResponse((prev) => prev + data.response)
    }

    // data = {id, context_id, history_id}
    const handleDone = (data) => {
      setMessages((prev) => [...prev, { type: 'ai', content: temp }])
      setTimeout(() => {
        temp = ''
      }, 100)
      setCurrentAiResponse('')
      setCurrentContextId(data.context_id)
      queryClient.invalidateQueries(QUERY_KEY_CURRENT_ACCOUNT)
      setStatus('idle')
    }

    // data = {id, error}
    const handleError = () => {
      setMessages((prev) => [...prev, { type: 'ai', content: temp }])
      setTimeout(() => {
        temp = ''
      })
      setCurrentAiResponse('')
      queryClient.invalidateQueries(QUERY_KEY_CURRENT_ACCOUNT)
      setStatus('idle')
    }

    channel.bind('response', handleResponse)
    channel.bind('done', handleDone)
    channel.bind('error', handleError)

    // eslint-disable-next-line consistent-return
    return () => {
      channel.unbind('response', handleResponse)
      channel.unbind('done', handleDone)
      channel.unbind('error', handleError)
    }
  }, [channel])

  const sendMessage = useCallback(
    async (content) => {
      if (!isConnected || !content) return

      try {
        setStatus('processing')

        // eslint-disable-next-line
        setMessages((prev) => [...prev, { type: 'user', content }])
        scrollToDiv()

        await client.runTask(taskId, {
          input: content,
          context_id: currentContextId,
        })

        const newChannelName = `result-${currentAccount?.id}-${taskId}`

        if (channel) {
          channel.unsubscribe()
        }

        const newChannel = pusher?.subscribe(newChannelName)

        setChannel(newChannel)
      } catch (error) {
        console.error('Error sending message:', error)
        setStatus('error')
      }
    },
    [isConnected, pusher, channel, currentAccount, taskId, currentContextId],
  )

  const unsubscribe = useCallback(() => {
    if (channel) {
      channel.unsubscribe()
      setChannel(null)
    }

    setStatus('idle')
  }, [channel])

  return {
    messages,
    resetChatMessages,
    currentAiResponse,
    sendMessage,
    unsubscribe,
    setMessages,
    isConnected,
    status,
    isLoadingCurrentAssitant,
    contextId: currentContextId,
    currentTask,
  }
}
