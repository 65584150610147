import React, { useState } from 'react'
import styles from './index.module.scss'

export type Props = {
  currentIndex?: number
  children: any
  tabs: any[]
}

export function Tabs({ children, currentIndex = 0, tabs }: Props) {
  const [currIndex, setIndex] = useState(currentIndex)

  if (!children) return null

  const currentChild = React.Children.toArray(children)[currIndex]

  if (React.isValidElement(currentChild)) {
    return (
      <div className={styles.container}>
        <ul className={styles.tabContainer}>
          {tabs?.map((tab, index) => (
            <button
              key={index}
              className={index === currIndex ? styles.tabActive : ''}
              type="button"
              onClick={() => setIndex(index)}
            >
              {tab}
            </button>
          ))}
        </ul>
        <div>{React.cloneElement(currentChild)}</div>
      </div>
    )
    // return React.cloneElement(currentChild)
  }

  return (
    <div className={styles.container}>
      <ul>
        {tabs?.map((tab, index) => (
          <button key={index} type="button" onClick={() => setIndex(index)}>
            {tab}
            {currIndex}
          </button>
        ))}
      </ul>
      <div>{currentChild}</div>
    </div>
  )
}
