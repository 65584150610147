import { Helmet } from 'react-helmet'
import { Container } from 'src/core/ds/container'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import { Blog } from '../landing/parts/Blog'
import { Faq } from '../landing/parts/Faq'
import { Upsell } from '../landing/parts/Upsell'
import { changelog } from './data'
import styles from './index.module.scss'

export function ChangeLogScreen() {
  return (
    <AppLayout>
      <Helmet>
        <title>Project changelog</title>

        <meta property="og:title" content="Project changelog" />
      </Helmet>
      <Container>
        <div className={styles.content}>
          <div className={styles.hero}>
            <h1>Project changelog</h1>
          </div>

          <div className={styles.box}>
            {changelog.reverse().map((logItem) => (
              <div key={logItem.id} className={styles.logItem}>
                <div className={styles.logItemDate}>{logItem.date}</div>
                <div className={styles.logItemTitle}>{logItem.title}</div>
                <div className={styles.logItemDescription}>{logItem.description}</div>
              </div>
            ))}
          </div>

          <Blog />

          <Faq />

          <Upsell />
        </div>
      </Container>
      <Footer />
    </AppLayout>
  )
}
