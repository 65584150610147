import { Navigate, useParams } from 'react-router-dom'

export function RefScreen() {
  const { refId } = useParams()

  if (refId) {
    localStorage.setItem('ref_id', refId as string)
  }

  return <Navigate to="/" />
}
