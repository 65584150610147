/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import { MetaTitle } from 'src/components/MetaTitle'
import { Container } from 'src/core/ds/container'
import { AppLayout } from 'src/layouts/AppLayout'
import { Footer } from 'src/layouts/Footer'
import { Blog } from '../landing/parts/Blog'
import { Faq } from '../landing/parts/Faq'
import { Upsell } from '../landing/parts/Upsell'
import styles from './index.module.scss'

export function TermsScreen() {
  return (
    <AppLayout>
      <MetaTitle title="Terms of use" />
      <Container>
        <div className={styles.content}>
          <div className={styles.hero}>
            <h1>Terms of Use for Zentask.AI</h1>
          </div>

          <div className={styles.content}>
            <p>
              Zentask.AI, a service operated by Muvon Co. Ltd ("Muvon", "we", "us", or "our"), registered in Thailand.
              Our website is <a href="https://muvon.io">https://muvon.io</a>. By using the services offered by
              Zentask.AI ("Service"), you agree to be bound by these Terms of Use ("Terms"). If you do not agree with
              any part of these Terms, you must not use our Service.
            </p>

            <h2>1. Description of Service</h2>
            <p>
              Zentask.AI allows users ("you") to generate images using AI models based on prompts without the need to
              upload personal photos. Additionally, we provide a list of various Large Language Models (LLMs) with whom
              you can chat. We make no guarantees regarding the accuracy or nature of the generated content, as
              variations may occur due to the nature of AI technology.
            </p>

            <h2>2. Use of Service</h2>
            <p>
              You are allowed to generate content and interact with LLMs using our Service. However, we are not
              responsible for any activities resulting in content that includes nudity, violence, or any objectionable
              material. It is prohibited for users to generate and distribute any content considered inappropriate or
              harmful. We reserve the right to block access or remove any content that we determine violates these Terms
              or any applicable laws.
            </p>

            <h2>3. Payments and Refund Policy</h2>
            <p>
              All payments made to use our Service are non-refundable due to the costs associated with operating AI
              models and maintaining service availability. Users have the option to purchase credits for pay-as-you-go
              services or subscribe for additional benefits. Please note that subscriptions expire monthly, and it is
              your responsibility to manage your subscription preferences.
            </p>

            <h2>4. Intellectual Property Rights</h2>
            <p>
              All content generated by our Service, including but not limited to images, texts, and interactions, is the
              property of Muvon Co. Ltd unless otherwise stated. You are granted a non-exclusive, non-transferable
              license to use the content generated through our Service for your personal, non-commercial purposes only.
            </p>

            <h2>5. User Conduct</h2>
            <p>
              You agree not to use the Service for any unlawful purpose or in any way that could damage, disable,
              overburden, or impair the Service. You agree not to attempt to gain unauthorized access to any part of the
              Service or to any other accounts, computer systems, or networks connected to the Service.
            </p>

            <h2>6. Disclaimer of Warranties</h2>
            <p>
              The Service is provided "as is" without any warranties of any kind. Muvon expressly disclaims all
              warranties, whether express or implied, including, but not limited to, the implied warranties of
              merchantability, fitness for a particular purpose, and non-infringement.
            </p>

            <h2>7. Limitation of Liability</h2>
            <p>
              Muvon, its affiliates, officers, directors, employees, agents, suppliers, or licensors shall not be liable
              for any indirect, incidental, special, consequential, or punitive damages, including without limitation,
              loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of
              or inability to access or use the Service.
            </p>

            <h2>8. Changes to Terms</h2>
            <p>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing
              to access or use our Service after those revisions become effective, you agree to be bound by the revised
              terms.
            </p>

            <h2>9. Governing Law</h2>
            <p>
              These Terms shall be governed and construed in accordance with the laws of Thailand, without regard to its
              conflict of law provisions.
            </p>

            <h2>10. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at{' '}
              <a href="mailto:hello@zentask.ai">hello@zentask.ai</a>.
            </p>

            <p>
              By using Zentask.AI, you acknowledge that you have read, understood, and agree to be bound by these Terms
              of Use.
            </p>
          </div>
        </div>

        <div className={styles.bb}>
          <Blog />

          <Faq />

          <Upsell />
        </div>
      </Container>
      <Footer />
    </AppLayout>
  )
}
