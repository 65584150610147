/* eslint-disable react/no-unescaped-entities */
import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { InputSizes } from 'src/core/ds/input/types'
import { useAuth } from 'src/entities/account/useAuth'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { FlowLayout } from 'src/layouts/flow/Flow.layout'

export function LoginEmail({ goNext, onCancelFlow, email, setEmail }) {
  const { requestCodeWithEmail } = useAuth()

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      // eslint-disable-next-line camelcase
      const ref_id = localStorage.getItem('refId')
      const utmRaw = localStorage.getItem('adsSource')
      const utm = JSON.parse(utmRaw || '{}') || {}
      await requestCodeWithEmail.mutateAsync({
        email: email?.toLowerCase(),
        // eslint-disable-next-line camelcase
        ref_id,
        utm: {
          ...utm,
          utm_url: window.location.href,
        },
      })
      goNext()
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <FlowLayout>
      <FlowLayout.Header onCancelFlow={onCancelFlow} />
      <FlowLayout.Body>
        <div style={{ maxWidth: 500, margin: '0 auto' }}>
          <FlowLayout.Title>
            <h1>Log in</h1>
          </FlowLayout.Title>
          <FlowLayout.Description>
            No password is required.
            <br />
            You need to obtain a new one-time code each time you log in.
          </FlowLayout.Description>
          {/* <FlowLayout.Description>
            <div style={{ fontSize: '.875rem', color: 'var(--color-secondary)' }}>
              Temporary problems with delivery emails to gmail. Try using "Login with Google" button instead.
            </div>
          </FlowLayout.Description> */}
          <form onSubmit={onSubmit}>
            <div style={{ marginBottom: '1rem' }}>
              <Input
                error={requestCodeWithEmail?.error?.toString()}
                value={email}
                label="Email"
                isRequired
                type="email"
                autofocus
                inputSize={InputSizes.l}
                name="description"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Enter your email address.."
              />
            </div>

            <div style={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center' }}>
              <Button
                size={ButtonSizes.xl}
                isFullWidth
                disabled={email.length < 3}
                isLoading={requestCodeWithEmail.isLoading}
                label="Continue"
                type="submit"
              />
            </div>
          </form>

          <div style={{ margin: '1rem 0', display: 'flex', justifyContent: 'center' }}>Or</div>
          <div style={{ width: '100%' }}>
            <Link to="https://auth.zentask.ai/google/">
              <Button
                size={ButtonSizes.xl}
                isFullWidth
                variant={ButtonVariants.secondary}
                isLoading={requestCodeWithEmail.isLoading}
                label="Login with Google"
                type="button"
                onClick={() => sendGAEvent('click_login_google')}
                icon={IconNames.google}
              />
            </Link>
            <div style={{ marginTop: '1rem' }}>
              <Link to="https://auth.zentask.ai/twitter/">
                <Button
                  size={ButtonSizes.xl}
                  isFullWidth
                  variant={ButtonVariants.secondary}
                  isLoading={requestCodeWithEmail.isLoading}
                  label="Login with"
                  type="button"
                  onClick={() => sendGAEvent('click_login_twitter')}
                  icon={IconNames.twitterX}
                />
              </Link>
            </div>
          </div>
        </div>
      </FlowLayout.Body>
    </FlowLayout>
  )
}
