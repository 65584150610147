import { ServerErrorEnum } from 'src/api/types'
import { pushNotification } from 'src/services/notifications/notificationService'

const SERVER_ERR_MAP = {
  e_account_unauthorized: 'server.error.notAuthorized',
  e_account_auth_missing: 'server.error.AuthMissing',
  e_character_not_ready: 'server.error.CharacterNotReady',
  e_account_balance_insufficient: 'server.error.InsufficientBalance',
  e_snap_concurrency_overflow: 'server.error.concurrencyOverflow',
}

export const getErrorMessage = (err: ServerErrorEnum) => SERVER_ERR_MAP[err] ?? `Unknown error: ${err}`

export const useServerNotification = () => {
  const notify = (error: ServerErrorEnum) => {
    const message = getErrorMessage(error)
    pushNotification({
      type: 'error',
      title: message,
      // subtitle: 'Some subttle test',
    })
  }

  return {
    notify,
  }
}
