export const testimonials = [
  {
    id: 1,
    title: '"Thrilled by the innovative concept!"',
    description:
      "I'm absolutely thrilled to see this AI marketplace! The concept of creating prompts and earning commission on each use is truly innovative. It's exciting to see a platform that brings the power of AI to engineers in such a seamless and user-friendly way.",
    authorName: 'Odin Enes Ozlen',
    authorImage:
      'https://ph-avatars.imgix.net/3921679/6c4eddf2-2585-4357-b8a7-5e5b078c443f?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2',
  },
  {
    id: 2,
    title: '"A time-saving product!"',
    description:
      'Hi, Nice product guys! Loved how it saves time by providing ready to use use-cases. Also loved the way website was designed, keeping in mind easability as the prominent factor.',
    authorName: 'Jatin Keni',
    authorImage:
      'https://ph-avatars.imgix.net/5412406/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2',
  },
  {
    id: 3,
    title: '"Zentask AI - My go-to task management tool!"',
    description:
      'Zentask AI has become my go-to task management tool. Its powerful features and intuitive interface make managing tasks a breeze. Give it a try and see the difference it can make in your productivity!',
    authorName: 'GS',
    authorImage: 'https://pbs.twimg.com/profile_images/1664876161724297216/6Gqj0uaN_x96.jpg',
  },
  {
    id: 4,
    title: '"Amazing design for ease of use!"',
    description:
      'Amazing Love it how this my saves time by providing ready to use use-cases. Also loved the way website was designed, keeping in mind easability as the prominent factor.',
    authorName: 'William Miranda',
    authorImage:
      'https://ph-avatars.imgix.net/5068604/61bd412a-1fda-4a8d-b701-e54dc3448c47?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2',
  },
  {
    id: 5,
    title: '"Great product!"',
    description:
      "Great product! It brings together so many AI-related services on one site. Why didn't someone think of this before? Can't wait to see what you'll do next.",
    authorName: 'John Toomey',
    authorImage:
      'https://ph-avatars.imgix.net/5140792/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2',
  },
  {
    id: 6,
    title: '"Exciting project!"',
    description: 'Amazing project I am really excited for this.',
    authorName: 'Melissa McCarthy',
    authorImage:
      'https://ph-avatars.imgix.net/5079278/dc90fb9e-c987-4396-9a65-b8737518e62b?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2',
  },
]
