import { Link } from 'react-router-dom'
import { DateFormats, formatDate } from 'src/utils/dates'
import { wrapSearchInput } from '../utils'
import styles from './index.module.scss'

export function HistoryItem({ isSelected, onSelect, item, isSearchResult = false, searchInput = '' }) {
  return (
    <Link
      to={`/history/${item?.context_id}${item?.context_id !== item?.id ? `#${item.id}` : ''}`}
      onClick={() => onSelect(item)}
      className={`${styles.item} ${isSelected ? styles.selected : undefined}`}
    >
      <div className={styles.itemHeader}>
        <Link to={`/chat/${item.task.slug}`}>
          <span>{item.task.title}</span>
        </Link>
        <p>{item.task.description}</p>
      </div>

      {!isSearchResult && <div className={styles.itemUserInput}>{item?.input}</div>}
      {isSearchResult && (
        <div
          className={styles.itemUserInput}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: wrapSearchInput(searchInput, item?.input),
          }}
        />
      )}

      {!isSearchResult && <div className={styles.itemContent}>{item?.output}</div>}
      {isSearchResult && (
        <div
          className={styles.itemContent}
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: wrapSearchInput(searchInput, item?.output),
          }}
        />
      )}

      <div className={styles.itemDate}>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <div>{formatDate(Number(item?.created_at * 1000) as Date, DateFormats.datePickerFullDate)}</div>
        <div className={styles.model}>{item.task.ai.name}</div>
      </div>
    </Link>
  )
}
