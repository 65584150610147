/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { apiClient } from 'src/api/api-client'
import { useModelApi, UseModelApiProps, UseModelApiResult } from 'src/api/core'
import { QUERY_KEY_HISTORY_BY_ID } from './queryKeys'

export const useGetHistoryById = (
  props?: UseModelApiProps & { historyId?: string },
): UseModelApiResult<{ input: string; output: string; id: string; task: any; context: any; context_id: string }> => {
  const client = apiClient.history()

  const query = useModelApi({
    ...props,
    id: 'historyById',
    enabled: !!props?.historyId,
    queryKey: [QUERY_KEY_HISTORY_BY_ID, props?.historyId] as unknown as string,
    queryFn: () => client.getHistoryItemById(props?.historyId),
  })

  return {
    ...query,
  }
}
