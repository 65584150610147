import { Link } from 'react-router-dom'
import { blogData } from 'src/blog/data'
import styles from '../index.module.scss'

export function Blog() {
  return null

  return (
    <section id="blog" className={styles.faq}>
      <h2>Blog</h2>

      <div className={styles.blogList}>
        {Object.keys(blogData)
          .slice(0, 3)
          .map((key) => (
            <div key={key} className={styles.blogItem}>
              <Link to={blogData[key].link}>
                <img src={blogData[key].image} alt={blogData[key].title} loading="lazy" />
              </Link>
              <Link to={blogData[key].link}>{blogData[key].title}</Link>
              <p>{blogData[key].description}</p>
            </div>
          ))}
      </div>
    </section>
  )
}
