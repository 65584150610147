import create from 'zustand'
import { persist } from 'zustand/middleware'

export const useChatStore = create<any>(
  persist(
    (set) => ({
      selectedTaskId: { id: 'ZEN', title: 'Talk to Zentask', value: 'ZEN' },
      setSelectedTask: (selectedTask: any) => set({ selectedTask }),
      selectedHistoryItem: null,
      setSelectedHistoryItem: (selectedHistoryItem: string) => set({ selectedHistoryItem }),
      currentContextId: null,
      setCurrentContextId: (currentContextId: string) => set({ currentContextId }),
    }),
    {
      name: 'chat-store', // Unique name for localStorage item
      partialize: (state) => {
        const { currentContextId, ...rest } = state

        return rest
      },
    },
  ),
)
