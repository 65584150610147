export function wrapSearchInput(inputText, apiOutputText) {
  // escape special characters in search input text
  const escapedInput = inputText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  // use regex to find the index of input text in api output text
  const regex = new RegExp(escapedInput, 'i')
  const match = apiOutputText.match(regex)

  if (!match) {
    return apiOutputText
  }

  const startIndex = Math.max(match.index - 120, 0)
  const endIndex = Math.min(match.index + match[0].length + 120, apiOutputText.length)

  // wrap the matching text with <span> tag
  const beforeText = apiOutputText.slice(startIndex, match.index)
  const afterText = apiOutputText.slice(match.index + match[0].length, endIndex)
  const highlightedText = `<span>${match[0]}</span>`

  return `${beforeText}${highlightedText}${afterText}`
}
