import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Textarea } from 'src/core/ds/textarea'
import { Tooltip } from 'src/core/ds/tooltip/Tooltip'
import { useMedia } from 'src/hooks/useMedia'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import { PinsList } from '../PinsList/PinsList.widget'
import styles from './index.module.scss'

export function ChatInput({
  resetChatMessages,
  sendMessage,
  hint,
  contextId,
  status,
  currentUser,
  isLoadingCurrentAssitant,
  isDisabled,
  currentTask,
}) {
  const [input, setInput] = useState('')
  const [scrolled, setScrolled] = useState(false)
  const navigate = useNavigate()
  const mediaWidth = useMedia()
  const setIsOpenTopupModal = useUIStore((state) => state.setIsOpenTopupModal)
  const setIsOpenPromptsSearch = useUIStore((state) => state.setIsOpenPromptsSearch)
  const userBalance = currentUser?.balance + currentUser?.expiring_balance || 0

  useEffect(() => {
    const handleScroll = () => {
      if (mediaWidth < 768) {
        if (window.scrollY > 100) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [mediaWidth])

  const onSubmit = (e) => {
    if (status === 'processing') return

    e.preventDefault()

    if (!currentUser) {
      navigate('/login')

      return
    }

    if (userBalance < 1) {
      pushNotification({
        type: 'error',
        title: 'No balance. Please, topup',
      })

      return
    }

    sendMessage(input)
    setInput('')
  }

  const userCredits = currentUser?.balance + currentUser?.expiring_balance - currentUser?.debt || 0

  const onChangeInput = (e) => {
    setInput(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      onSubmit(e)
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {userCredits < config.settings.minBalanceToShowTopup && (
          <div className={styles.balanceFlex}>
            <span>
              Credits:&nbsp;
              {userCredits}
            </span>
            <div className={styles.lowBalance}>
              Your balance is low.
              <Button
                onClick={() => setIsOpenTopupModal(true)}
                label="Buy credits"
                variant={ButtonVariants.unstyled}
                type="button"
              />
            </div>
          </div>
        )}
        {currentUser && (
          <div style={mediaWidth < 768 ? { top: scrolled ? '16px' : '76px' } : {}} className={styles.pinsContainer}>
            <div className={styles.pinsSettings}>
              <Button
                iconColor="var(--color-white)"
                icon={IconNames.settings}
                variant={ButtonVariants.unstyled}
                type="button"
                tooltipOnLeft
                onClick={() => setIsOpenPromptsSearch(true)}
                label=""
                tooltip="Assistant settings"
              />
            </div>
            <div className={styles.pins}>
              <PinsList isLoadingCurrentAssitant={isLoadingCurrentAssitant} currentAssistant={currentTask} />
            </div>
            {currentTask?.description && <Tooltip content={currentTask?.description} />}
          </div>
        )}
        <div className={styles.form}>
          <form onSubmit={onSubmit}>
            {input.length > 0 && (
              <span className={styles.maxInput}>
                {/* eslint-disable-next-line */}
                {input?.length} chars
              </span>
            )}
            {contextId && (
              <div className={styles.settings}>
                <Button
                  iconColor="var(--color-white)"
                  icon={IconNames.mop}
                  variant={ButtonVariants.unstyled}
                  type="button"
                  tooltipOnLeft
                  tooltip="Start new chat"
                  onClick={resetChatMessages}
                  label=""
                />
              </div>
            )}

            <Textarea
              onKeyPress={handleKeyPress}
              onChange={onChangeInput}
              value={input}
              placeholder={hint}
              isDisabled={!currentUser || isDisabled}
              autoFocus
            />
            <Button
              icon={IconNames.send}
              disabled={status === 'processing'}
              variant={ButtonVariants.unstyled}
              type="submit"
              isLoading={status === 'processing'}
              tooltip={status === 'processing' ? 'Disabled on processing' : undefined}
              label=""
            />
            {status !== 'processing' && <span className={styles.tip}>Submit on Enter</span>}
          </form>
        </div>
      </div>
    </div>
  )
}
