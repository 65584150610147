import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { IconNames } from 'src/core/ds/icon'
import styles from '../index.module.scss'

export function Features() {
  return (
    <section id="prompts" className={styles.advantages}>
      <h2>The All-In-One AI</h2>
      <p>
        With Zentask you get access to all-in-one AI models with a single subscription. Boost your productivity and save time and money every day.
        Register and start using ChatGPT, Claude, and Gemini Pro. Best alternative for the same money.
      </p>

      <div className={styles.newFeaturesList}>
        <div className={styles.newFeatureItem}>
          <div className={styles.newFeatureItemContent}>
            <h3>All-in-one AI</h3>
            <p>
              Best alternative to ChatGPT, GPT-4, Claude and Google&#39;s Gemini Pro. Ultimate experience with all-in-one AI solutions with a single subscription. Save time and money every day. Create your own tasks, add custom tools, index your data to interact with by utilizing RAG. Zentask provides the smartest AI models and tools for the best AI experience in a single subscription.
            </p>
            <div className={styles.newFeatureItemContentAction}>
              <Link to="/chat/talk-to-zentask">
                <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
              </Link>
            </div>
          </div>
          <div className={styles.newFeatureItemImage}>
            <img
              src="https://cdn.zentask.ai/image/240901/landing-all-in-one-ai.webp"
              alt="All-in-one AI"
              loading="lazy"
            />
          </div>
        </div>

        <div className={styles.newFeatureItem}>
          <div className={styles.newFeatureItemContent}>
            <h3>AI assistants for your needs</h3>
            <p>
              With single subscription you get access to multiple AI assistants that utilize the most advanced models in the industry. Pay once to get access to multiple AI models. Use GPT-4 for complex data analysis, DALL-E for stunning visuals, Claude for creative writing, and Stable Diffusion for generating images. If you&#39;re missing some assistant, just create it. Create viral social posts, generate content, learn to code, write articles with images, and more.
            </p>
            <div className={styles.newFeatureItemContentAction}>
              <Link to="/chat/talk-to-zentask">
                <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
              </Link>
            </div>
          </div>
          <div className={styles.newFeatureItemImage}>
            <img
              src="https://cdn.zentask.ai/image/240901/landing-ai-assistants-for-your-needs.webp"
              alt="AI assistants for your needs"
              loading="lazy"
            />
          </div>
        </div>

        <div className={styles.newFeatureItem}>
          <div className={styles.newFeatureItemContent}>
            <h3>Get your team in sync (soon)</h3>
            <p>
              Work with Zentask assistants in your own team. Share your tasks, collaborate, and get the most out of your AI assistants. Create your own tasks, add custom tools and interact with your data (upcoming). Boost your productivity like never before, share the common context and knowledge base with your team.
            </p>
            <div className={styles.newFeatureItemContentAction}>
              <Link to="/chat/talk-to-zentask">
                <Button iconColor="#fff" icon={IconNames.arrowRight} label="Try now" type="button" />
              </Link>
            </div>
          </div>
          <div className={styles.newFeatureItemImage}>
            <img
              src="https://cdn.zentask.ai/image/240901/landing-get-your-team-in-sync.webp"
              alt="Get your team in sync"
              loading="lazy"
            />
          </div>
        </div>

        <div className={styles.newFeatureItem}>
          <div className={styles.newFeatureItemContent}>
            <h3>Tools, context and RAG (soon)</h3>
            <p>
              AI models are powerful tools, but they can&#39;t do everything. That&#39;s why it&#39;s required to load custom context or knowledge base. With Zentask you can create your own indexer for your custom AI assistant (RAG), configure tools, like fetching some external information, searching the web, or even using any of your APIs to get the data, and execute your tasks in the most efficient way possible! Boost productivity with real-time context to the maximum. (upcoming)
            </p>
            <div className={styles.newFeatureItemContentAction}>
              <Link to="/chat/talk-to-zentask">
                <Button iconColor="#ff" icon={IconNames.arrowRight} label="Try now" type="button" />
              </Link>
            </div>
          </div>
          <div className={styles.newFeatureItemImage}>
            <img
              src="https://cdn.zentask.ai/image/240901/landing-tools-context-rag.webp"
              alt="Tools, context and RAG"
              loading="lazy"
            />
          </div>
        </div>

        <div className={styles.newFeatureItem}>
          <div className={styles.newFeatureItemContent}>
            <h3>Create, develop and earn (soon)</h3>
            <p>
              If you are a developer, create pluggable tools for AI assistants, open them to the public, and earn commissions from them. Build tools for your website and let people use them on Zentask. Create your own assistant, choose the best-suited AI model for the task, and make it public to get paid for it while people use it. Imagination is the limit, so let&#39;s build the future together! (upcoming)
            </p>
            <div className={styles.newFeatureItemContentAction}>
              <Link to="/chat/talk-to-zentask">
                <Button iconColor="#ff" icon={IconNames.arrowRight} label="Try now" type="button" />
              </Link>
            </div>
          </div>
          <div className={styles.newFeatureItemImage}>
            <img
              src="https://cdn.zentask.ai/image/240901/landing-earn-from-your-ai-assistants.webp"
              alt="Create, develop and earn"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
