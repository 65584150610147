import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiError, ServerErrorEnum } from 'src/api/types'

export const useRouter = () => {
  const navigate = useNavigate()

  return useMemo(
    () => ({
      refresh: () => navigate(0),
      goBack: () => navigate(-1),
      goForward: () => navigate(1),
      generateErrorLink: (error?: ApiError | null) => {
        if (error === ServerErrorEnum.UserNotAuthorized) {
          return `/unauthorized`
        }

        if (error === ServerErrorEnum.HistoryNotFound) {
          return `/404`
        }

        return `/error`
      },
    }),
    [navigate],
  )
}
