export const copyToClipboard = async (text: string, cb: any) => {
  // if (!navigator.clipboard) return
  navigator.clipboard.writeText(text).then(() => {
    // console.log('Async: Copying to clipboard was successful!')
    if (typeof cb === 'function') {
      cb()
    }
  })
}

export function getWordCount(str = '') {
  // Split the string into words using space as separator

  // Use the regex /\s+/ to match one or more whitespace characters

  const words = str?.split(/\s+/) || []

  // Filter out empty strings to accurately count words

  const filteredWords = words.filter((word) => word.length > 0)

  // Return the number of words

  return filteredWords.length
}

export function isSignUpEvent(timestamp) {
  // Given timestamp
  // var timestamp = 1708279338
  const date = new Date(timestamp * 1000) // Convert to milliseconds and create Date object

  // Get current date and time
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const difference = currentDate.getTime() - date.getTime()

  // Convert milliseconds into minutes
  const differenceInMinutes = difference / (1000 * 60)

  // Check if the difference is 10 minutes or more
  if (differenceInMinutes >= 10) {
    return false
  }

  return true
}
