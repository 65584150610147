// import { Link } from '@tanstack/react-location'
import { Link } from 'react-router-dom'
import { config } from 'src/config/config'
// import LogoImage from '../../assets/images/zentask-new-logo.png'
import styles from './index.module.scss'

export function Logo() {
  return (
    <Link to="/chat/talk-to-zentask" className={styles.logo}>
      {/* <img width="44" height="44" loading="lazy" src={LogoImage} alt="Zentask logo" /> */}
      <p>{config.siteName}</p>
      <span>BETA</span>
    </Link>
  )
}
