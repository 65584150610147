// import posthog from 'posthog-js'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { IconsSpriteContainer } from 'src/core/ds/iconsSprite'
import { ScrollToTop } from 'src/hooks/scrollToTop'
import { locale } from 'src/locale/en-US'
import { queryClient } from 'src/queries/reactQueryClient'
import { BlogScreen } from 'src/screens/blog/blog.screen'
import { ChangeLogScreen } from 'src/screens/changelog/ChangeLog.screen'
import { InfluencersScreen } from 'src/screens/influencers/influencers.screen'
import { LandingScreen } from 'src/screens/landing/landing.screen'
import { LoginScreen } from 'src/screens/login/login.screen'
import { MagicLinkScreen } from 'src/screens/magic-link/MagicLink.screen'
import { PricesScreen } from 'src/screens/prices/Prices.screen'
import { RefScreen } from 'src/screens/RefScreen/Ref.screen'
import { TeamScreen } from 'src/screens/team/Team.screen'
import { TermsScreen } from 'src/screens/terms/Terms.screen'
import { ZenScreen } from 'src/screens/zen/zen.screen'
import { useLanguageStore } from 'src/services/store/LanguageStore'
import { LocalisationProvider } from 'src/services/store/TranslationStore'
import { createTranslationService, TranslationProvider } from 'src/services/translation/translation-service'
import { flattenMessages } from 'src/utils/translation'
import { ErrorRoute } from './error.route'
import { SEORoutes } from './seo.router'
import { WithAnonymousInitialData, WithInitialData } from './utils'

function AppRoutes() {
  ScrollToTop()

  return (
    <Routes>
      {/* SEO ROUTES */}
      {SEORoutes()}
      {/* Anonymous routes */}
      <Route path="/" element={<LandingScreen />} />
      <Route path="/ref/:refId" element={<RefScreen />} />
      <Route path="/auth" element={<MagicLinkScreen />} />

      <Route element={<WithAnonymousInitialData />}>
        <Route path="*" element={<ErrorRoute />} />
        <Route path="login" element={<LoginScreen />} />
        <Route path="zen" element={<ZenScreen />} />
        <Route path="unauthorized" element={<div>level 2 anon</div>} />
        <Route path="chat/:taskSlug" element={<ZenScreen />} />
      </Route>
      {/* User routes */}
      <Route element={<WithInitialData />}>
        {/* <Route path="login" element={<LoginScreen />} /> */}
        {/* <Route path="dashboard" element={<DashboardScreen />} />
        <Route path="favorites" element={<FavoritesScreen />} /> */}
        {/* <Route path="pay/*" element={<div>level3</div>} /> */}
        <Route path="history/:historyId" element={<ZenScreen />} />
        {/* <Route path="onboarding" element={<ModelCreationScreen />} />
        <Route path="create" element={<CreateScreen />} />
        <Route path="add-new-model" element={<ModelCreationScreen />} /> */}
      </Route>
      <Route path="influencers" element={<InfluencersScreen />} />
      <Route path="team" element={<TeamScreen />} />
      <Route path="prices" element={<PricesScreen />} />
      <Route path="changelog" element={<ChangeLogScreen />} />
      <Route path="terms-of-use" element={<TermsScreen />} />
      <Route path="blog" element={<BlogScreen />} />
      <Route path="blog">
        {/* <Route path="create-stunning-instagram-photos-without-leaving-home-the-power-of-ai" element={<BlogPageOne />} /> */}
      </Route>
    </Routes>
  )
}

export function MainApp() {
  const currentLocale = useLanguageStore((state) => state.currentLocale)
  const updateLanguage = useLanguageStore((state) => state.updateLanguage)

  useEffect(() => {
    document.body.dataset.appRendered = 'true'
  }, [])

  const intl = createTranslationService({
    locale: currentLocale,
    defaultLocale: currentLocale,
    formats: {},
    defaultFormats: {},
    messages: flattenMessages(locale[currentLocale]),
  })

  // posthog.init('phc_vixqlX0oJ6lSWfGRC0FJpEuUVpNVs0kH1CyWHWrnZBF', {
  //   api_host: 'https://eu.posthog.com',
  //   persistence: 'memory',
  // })

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        {/* eslint-disable-next-line */}
        <LocalisationProvider currentLocale={currentLocale} updateCurrentLocal={updateLanguage}>
          <TranslationProvider value={intl}>
            <IconsSpriteContainer />
            <AppRoutes />
            <Toaster position="top-right" />
          </TranslationProvider>
        </LocalisationProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
