import { Route } from 'react-router-dom'
// eslint-disable-next-line import/no-cycle
import { SEOLandingScreen } from 'src/screens/landing/seolanding.screen'

export const seo = [
  {
    urlSlug: 'ai-image-generator',
    id: 'aiimagegen001',
    keyword: 'ai image generator',
    title: 'Create Stunning AI-Generated Images Instantly',
    subtitle: 'Leverage our AI Image Generator to transform your photos into Instagram-ready masterpieces from home.',
    features: {
      title: 'Quick and Easy',
      description: `Our AI Image Generator enables you to produce breathtaking AI-generated photos in seconds. It's an indispensable tool for Instagram influencers, brands, and anyone aiming to boost their online presence with high-quality visuals.`,
    },
    upsell: {
      title: 'Unlock More',
      subtitle: 'Enhance your creativity with our AI Image Generator premium features now!',
    },
    article:
      'In the era of digital content creation, AI image generators have revolutionized the way we produce visuals. With our advanced AI Image Generator, creating stunning, professional-grade images has never been easier. Ideal for enhancing your Instagram feed or professional branding, our tool offers a quick, cost-effective solution for all your imaging needs. Experience the transformative power of AI-generated images and elevate your digital presence effortlessly.',
    testimonials: [
      {
        id: 1,
        title: '"Elevating my Instagram has never been simpler!"',
        description:
          'The AI image generator transformed my social media strategy, allowing me to produce captivating visuals effortlessly.',
        authorName: 'Mia',
        authorImage: 'https://photo-dev.snaptap.ai/113657790681055233/144384891075690497/157649169027170305-2.webp',
      },
      {
        id: 2,
        title: '"A revolutionary tool for online content creation!"',
        description:
          'This AI image generator saves me so much time and money. Highly recommend for any serious online creator.',
        authorName: 'Noah',
        authorImage: 'https://photo.snaptap.ai/145101225770090497/148553925375557633/154498504473968641-2.webp',
      },
      {
        id: 3,
        title: '"Changed how I think about creating digital images."',
        description:
          "It's incredible how quickly I can turn my ideas into stunning visuals. This tool is a must-have for anyone looking to stand out online.",
        authorName: 'Emma',
        authorImage: 'https://api.picogen.io/files/202401/19/ab53e6eaa6a0f0b7e5e143aa13cb5f9f.png',
      },
      {
        id: 4,
        title: '"A vital asset for my branding toolkit."',
        description:
          "Thanks to this AI image generator, I've been able to maintain a consistent and professional look across all my digital platforms with minimal effort.",
        authorName: 'Oliver',
        authorImage: 'https://photo.snaptap.ai/145101225770090497/145153814138191873/145265396860059649-2.webp',
      },
    ],
  },
]

export function SEORoutes() {
  return seo.map((data) => <Route key={data.urlSlug} path={data.urlSlug} element={<SEOLandingScreen data={data} />} />)
}
