export const changelog = [
	{
		id: 1,
		title: 'Zentask 2.0 Beta',
		description: 'Launching the project for the first test users with the goal of collecting feedback.',
		date: '2024-08-24',
	}, {
		id: 2,
		title: 'Social Authentication',
		description: 'Introducing social authentication for users to easily sign in with their preferred social media accounts. Google and X available',
		date: '2024-09-01',
	},
]
