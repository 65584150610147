export const config = {
  baseUrl: process.env.REACT_APP_APP_ENV === 'production' ? 'https://api.zentask.ai' : 'http://api.zentask.zz:8084',
  baseAPIUrl: process.env.REACT_APP_APP_ENV === 'production' ? 'https://api.zentask.ai' : 'http://api.zentask.zz:8084',
  siteName: 'Zentask',
  pusherKey: process.env.REACT_APP_APP_ENV === 'production' ? '84Lqhv5js9K87JB2Ks0h' : 'QQQY6fIKRq04piypIio8',
  pusherHost: process.env.REACT_APP_APP_ENV === 'production' ? 'ws.muvon.dev' : 'ws.kit.muvon.zz',
  pusherPort: process.env.REACT_APP_APP_ENV === 'production' ? 443 : 8085,
  pusherTransport: process.env.REACT_APP_APP_ENV === 'production' ? 'wss' : 'ws',
  // eslint-disable-next-line no-unneeded-ternary
  pusherForceTSL: process.env.REACT_APP_APP_ENV === 'production' ? true : false,
  turnstileSiteKey: '0x4AAAAAAADaAKssW_ZClFec',
  baseMailUrl: 'https://mailer.muvon.dev',
  projectKey: 'zentask',
  site: {
    contactEmail: 'hello@zentask.ai',
    name: 'Zentask',
    socialLinks: {
      twitter: 'https://x.com/zentaskai?lang=en',
      linkedin: 'https://www.linkedin.com/company/13463009',
      slack: 'https://join.slack.com/t/muvon-community/shared_invite/zt-2ciam1emd-WWN52E7ljMrlziD7N18Sug',
    },
  },
  influencersFormLink:
    'https://docs.google.com/forms/d/e/1FAIpQLSfQo_6My3vOMN8q_eYR49MD6X3cOb9wsOBtLZ8yjjHu0dE11Q/viewform',
  features: {
    maxConcurrentRequests: 1,
    maxConcurrentRequestsPRO: 2,
  },
  settings: {
    minBalanceToShowTopup: 100,
  },
}
