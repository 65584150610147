/* eslint-disable react/no-unescaped-entities */
import styles from '../index.module.scss'

export function Faq() {
  return (
    <section id="faq" className={styles.faq}>
      <h2>Frequently Asked Questions (FAQs) about Zentask AI Platform</h2>
      <p>
        Find answers to your questions about Zentask, your all-in-one AI service platform for enhanced productivity and innovation.
      </p>

      <div className={styles.faqList}>
        <div className={styles.faqItem}>
          <span>What is Zentask and how does it differ from other AI platforms?</span>
          <p>
            Zentask is a comprehensive AI service that provides access to multiple AI models including Claude, Gemini PRO, ChatGPT, and image generation models like Stable Diffusion, DALL-E, and Flux. Unlike individual AI services, Zentask offers a single subscription for access to all these models, making it a cost-effective and efficient alternative for users seeking diverse AI capabilities.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>What are the key features of Zentask?</span>
          <p>
            Zentask offers an all-in-one AI experience with features like "Talk to Zentask" for Google searches, image generation, and various tasks. We provide multiple AI assistants for different needs, semantic search in history, task pinning, and early access to updates. Upcoming features include custom task creation, context-aware tasks with RAG (Retrieval-Augmented Generation), and team collaboration tools.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How does Zentask pricing work?</span>
          <p>
            Zentask uses a credit system, with each credit costing $0.002. Model and tool usage varies in credit cost. Subscribers receive bonus credits and access to premium features. We also offer a pay-as-you-go option for non-subscribers, ensuring flexibility for all users.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>What benefits do Zentask subscribers get?</span>
          <p>
            Subscribers receive extra credits, access to semantic search in history and tasks, content pinning capabilities, and early access to the latest updates and features. They also get the advantage of using multiple AI models under a single subscription, saving both time and money.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How does Zentask ensure optimal AI selection for tasks?</span>
          <p>
            Zentask provides access to multiple top-tier AI models within a single platform, allowing users to choose the best tool for each specific task. Our platform recommends the most suitable AI model based on the nature of your task, maximizing efficiency and effectiveness.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>What upcoming features is Zentask developing?</span>
          <p>
            We're working on features for custom task creation, context-aware tasks using RAG to fetch and utilize up-to-date information, team collaboration tools, and the ability for developers to create and monetize their own AI assistants and tools.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How does Zentask compare to individual AI services in terms of cost and convenience?</span>
          <p>
            Zentask offers the convenience of accessing multiple AI models and tools through a single interface and subscription, potentially saving users time and money compared to managing multiple separate services. It provides a unified experience with consistent pricing across different AI models.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Can businesses and teams use Zentask effectively?</span>
          <p>
            Yes, Zentask is developing team collaboration features that will allow sharing tasks, collaborating on projects, and maintaining a common context and knowledge base within teams. This makes Zentask an excellent choice for businesses looking to integrate AI into their workflows efficiently.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>What image generation capabilities does Zentask offer?</span>
          <p>
            Zentask provides access to multiple image generation models including DALL-E and Stable Diffusion. These AI models can create stunning visuals, generate content with images, and assist in various creative tasks, making Zentask a versatile platform for both text and image-based AI tasks.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Are there opportunities for developers on the Zentask platform?</span>
          <p>
            Yes, Zentask is developing features that will allow developers to create pluggable tools for AI assistants, build custom assistants, and monetize their creations. This upcoming feature will enable developers to earn commissions from their tools and assistants when used by other Zentask users.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How does Zentask handle data privacy and security?</span>
          <p>
            Zentask takes data privacy and security seriously. We implement industry-standard encryption protocols and adhere to strict data protection policies. User data is not used to train AI models, and we ensure compliance with relevant data protection regulations.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Can I integrate Zentask with my existing workflow or tools?</span>
          <p>
            While full integration capabilities are still in development, Zentask is designed to be flexible and adaptable. In the future, we plan to offer API access and integration options to allow seamless incorporation of Zentask's AI capabilities into various workflows and tools.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How often does Zentask update its AI models and features?</span>
          <p>
            Zentask regularly updates its AI models and features to ensure users have access to the latest advancements in AI technology. We strive to implement updates and new features on a regular basis, with subscribers getting early access to these improvements.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>Is there a free trial available for Zentask?</span>
          <p>
            Yes, new users can try Zentask with a limited number of free credits to experience our platform's capabilities. This allows you to explore various AI models and features before committing to a subscription or pay-as-you-go plan.
          </p>
        </div>

        <div className={styles.faqItem}>
          <span>How can I request a new feature or get support?</span>
          <p>
            To request a new feature, provide feedback, or get support, you can reach us by email at hello@zentask.ai. We value user input and are committed to continually improving our platform based on user needs and suggestions.
          </p>
        </div>
      </div>
    </section>
  )
}
