import { DrawerWrapper } from 'src/components/DrawerWrapper/DrawerWrapper'
import { UserArea } from 'src/components/UserArea'
import { UserDrawer } from 'src/components/UserDrawer'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { FeatureModal } from 'src/entities/subscription/FeatureModal'
import { sendGAEvent, useAnalytics } from 'src/hooks/useAnalytics'
import { useUIStore } from 'src/services/store/UIStore'
import { HistorySearch } from 'src/widgets/HistorySearch/HistorySearch'
import { PromptsSearch } from 'src/widgets/PromptsSearch/PromptsSearch'
import { Topup } from 'src/widgets/Topup/Topup'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './index.module.scss'
import { Logo } from './Logo'

export function ZenLayout({ children }) {
  useAnalytics()
  const isOpenPromptsSearch = useUIStore((state) => state.isOpenPromptsSearch)
  const setIsOpenPromptsSearch = useUIStore((state) => state.setIsOpenPromptsSearch)
  const isOpenHistorySearch = useUIStore((state) => state.isOpenHistorySearch)
  const setIsOpenHistorySearch = useUIStore((state) => state.setIsOpenHistorySearch)
  const isOpenUserarea = useUIStore((state) => state.isOpenUserarea)
  const setIsOpenUserarea = useUIStore((state) => state.setIsOpenUserarea)
  const isOpenTopupModal = useUIStore((state) => state.isOpenTopupModal)
  const setIsOpenTopupModal = useUIStore((state) => state.setIsOpenTopupModal)
  const { data: currentUser } = useCurrentAccount()

  return (
    <>
      <div className={styles.zenHeaderWrapper}>
        <nav className={styles.nav}>
          {currentUser && <Logo />}
          {!currentUser && <Header />}
          {currentUser && (
            <div className={styles.userSection}>
              <Button
                iconColor="var(--color-white)"
                icon={IconNames.bills}
                variant={ButtonVariants.unstyled}
                type="button"
                // tooltipOnLeft
                tooltip="History"
                onClick={() => {
                  setIsOpenHistorySearch(true)

                  sendGAEvent('click_open_history')
                }}
                label=""
              />
              <Button
                iconColor="var(--color-white)"
                icon={IconNames.robot}
                variant={ButtonVariants.unstyled}
                type="button"
                // tooltipOnLeft
                tooltip="Search assistants"
                onClick={() => {
                  setIsOpenPromptsSearch(true)
                  sendGAEvent('click_open_assistants_search')
                }}
                label=""
              />
              <UserArea currentUser={currentUser} />
            </div>
          )}
        </nav>
      </div>
      {children}
      <DrawerWrapper
        title="Assistants"
        width="370px"
        isOpen={isOpenPromptsSearch}
        onClose={() => setIsOpenPromptsSearch(false)}
      >
        <PromptsSearch isEnabled={isOpenPromptsSearch} />
      </DrawerWrapper>

      <DrawerWrapper
        title="History"
        width="370px"
        isOpen={isOpenHistorySearch}
        onClose={() => setIsOpenHistorySearch(false)}
      >
        <HistorySearch isEnabled={isOpenHistorySearch} />
      </DrawerWrapper>

      {currentUser && (
        <DrawerWrapper title="Account" width="370px" isOpen={isOpenUserarea} onClose={() => setIsOpenUserarea(false)}>
          <UserDrawer currentUser={currentUser} />
        </DrawerWrapper>
      )}
      <Modal withCloseButton onClose={() => setIsOpenTopupModal(false)} isOpen={isOpenTopupModal} body={<Topup />} />
      <FeatureModal />
      {!currentUser && <Footer />}
    </>
  )
}
