import { Link } from 'react-router-dom'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Modal } from 'src/core/ds/modal'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { Tier2 } from 'src/screens/prices/PricesPicogen'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

function BasicPlan() {
  const setIsPwaywallOpen = useUIStore((state) => state.setIsPwaywallOpen)

  return (
    <div className={styles.freeSection}>
      <h3>Free</h3>
      <h4>Basic</h4>
      <div className={styles.advantages}>
        <p>
          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
          100 Signup credits
        </p>
        <p>
          <Icon name={IconNames.checkFill} color="var(--color-primary)" />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          GPT, Claude, Gemini Pro, <br /> Stable Diffusion, Pixart, Flux.1 and more
        </p>
        <p>
          <Icon name={IconNames.close} color="var(--color-red)" />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          History search
        </p>
        <p>
          <Icon name={IconNames.close} color="var(--color-red)" />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          Pin and organize assistants
        </p>

        <p>
          <Icon name={IconNames.close} color="var(--color-red)" />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          Parallel requests (upcoming)
          <sup>1</sup>
        </p>
        <p>
          <Icon name={IconNames.close} color="var(--color-red)" />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          Pin and organize history (upcoming)
        </p>
      </div>

      <Button
        onClick={() => {
          sendGAEvent('subscription_suggest_click_basic')
          setIsPwaywallOpen(false)
        }}
        variant={ButtonVariants.secondary}
        type="button"
        label="Continue with Basic"
      />
    </div>
  )
}

const titleByFeatureMap = {
  pins: 'Start using Pins feature today',
  historySearch: 'Start using History search feature',
}

function PaywallDynamicTitle({ featureName }) {
  const title = titleByFeatureMap[featureName]

  return <h1>{title}</h1>
}

function PaywallModal({ featureName }) {
  const token = (localStorage && localStorage.getItem('token')) || ''

  return (
    <div>
      <div className={styles.content}>
        <PaywallDynamicTitle featureName={featureName} />
        <div className={styles.body}>
          <BasicPlan />
          <div className={styles.premiumSection}>
            <h3>
              $19.90
              <span> / month</span>
            </h3>
            <h4>
              PRO
              <span>Best Deal 🏆</span>
            </h4>
            <Tier2 creds="11,000" />
            <form
              onSubmit={() => sendGAEvent('subscription_suggest_click_subscribe')}
              action={`${config.baseAPIUrl}/account/pay/pro?value=1`}
              method="post"
            >
              <input hidden name="TKN" value={token} />
              <Button type="submit" label="Get started for $19.90" />
            </form>
          </div>
        </div>
      </div>

      <Link to="/prices">View all paid plans</Link>
    </div>
  )
}

export function FeatureModal() {
  const isPaywallOpen = useUIStore((state) => state.isPaywallOpen)
  const setIsPwaywallOpen = useUIStore((state) => state.setIsPwaywallOpen)
  const lastTriedPremiumFeature = useUIStore((state) => state.lastTriedPremiumFeature)

  return (
    <Modal
      withCloseButton
      body={<PaywallModal featureName={lastTriedPremiumFeature} />}
      onClose={() => setIsPwaywallOpen(false)}
      isOpen={isPaywallOpen}
    />
  )
}
