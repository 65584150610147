/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames, IconSize } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Modal } from 'src/core/ds/modal'
import { useAuth } from 'src/entities/account/useAuth'
import { useManagePlan } from 'src/entities/subscription/useManagePlan'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { pushNotification } from 'src/services/notifications/notificationService'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function UserDrawer({ currentUser }) {
  const { data: managePlanLink } = useManagePlan()
  const [isOpenLogoutModal, setOpenLogoutModal] = useState(false)
  const setIsOpenTopupModal = useUIStore((state) => state.setIsOpenTopupModal)
  const setIsOpenUserarea = useUIStore((state) => state.setIsOpenUserarea)
  const { logout } = useAuth()
  const navigate = useNavigate()

  const openLogoutModal = () => {
    setOpenLogoutModal(true)
  }

  const logoutUser = async () => {
    try {
      await logout.mutateAsync()
      pushNotification({
        type: 'success',
        title: 'Logged out',
      })
      navigate('/')
    } catch (e) {
      pushNotification({
        type: 'error',
        title: 'notification.auth.logout.fail',
      })
    }
  }

  return (
    <div className={styles.container}>
      <Modal
        isOpen={isOpenLogoutModal}
        title="Are you sure?"
        type="default"
        onClose={() => setOpenLogoutModal(false)}
        onDeny={() => setOpenLogoutModal(false)}
        onConfirm={logoutUser}
      />
      <div className={styles.label}>Email</div>
      <div className={styles.content}>{currentUser.email}</div>
      <div className={styles.label}>Balance</div>
      <div className={styles.contentFlex}>
        <span>{currentUser.balance + currentUser.expiring_balance - currentUser.debt}</span>

        <Button
          onClick={() => {
            setIsOpenTopupModal(true)
            sendGAEvent('click_open_topup')
          }}
          type="button"
          variant={ButtonVariants.secondary}
          label="Topup"
        />
      </div>

      <div className={styles.label}>Subscription plan</div>
      {currentUser.sub_plan && (
        <div style={{ textTransform: 'capitalize' }} className={styles.contentFlex}>
          <span>{currentUser.sub_plan}</span>
          {managePlanLink && (
            <Link onClick={() => sendGAEvent('click_manage_plan')} to={managePlanLink}>
              Manage plan
            </Link>
          )}
        </div>
      )}
      {!currentUser.sub_plan && (
        <div className={styles.contentFlex}>
          Zentask Free
          <Link to="/prices">
            <Button onClick={() => setIsOpenUserarea(false)} type="button" label="upgrade" />
          </Link>
        </div>
      )}

      {currentUser?.ref_share > 0 && (
        <>
          <div className={styles.label}>Your referal link</div>
          <div className={styles.content}>
            <Input readOnly value={`https://zentask.ai/ref/${currentUser?.id}`} />
          </div>
        </>
      )}

      <div className={styles.label}>Links</div>
      <div className={styles.links}>
        <ul>
          <li>
            <Link onClick={() => setIsOpenUserarea(false)} to="/prices">
              Prices
            </Link>
          </li>
          <li>
            <Link onClick={() => setIsOpenUserarea(false)} to="/team">
              Team
            </Link>
          </li>
          <li>
            <Link onClick={() => setIsOpenUserarea(false)} to="/influencers">
              Influencer program
            </Link>
          </li>
          <li>
            <a onClick={() => setIsOpenUserarea(false)} href="/changelog">
              Changelog
            </a>
          </li>
          <li>
            <a onClick={() => setIsOpenUserarea(false)} href="/terms-of-use">
              Terms of use
            </a>
          </li>
        </ul>
      </div>

      <div className={styles.label}>Contacts</div>
      <div className={styles.socials}>
        <a target="_blank" title="hello@zentask.ai" href="mailto:hello@zentask.ai" rel="noreferrer">
          <Icon size={IconSize.s} name={IconNames.email} color="var(--color-secondary)" />
        </a>
        <a target="_blank" title="Zentask.ai twitter" href="https://twitter.com/zentaskai" rel="noreferrer">
          <Icon size={IconSize.s} name={IconNames.twitter} color="var(--color-secondary)" />
        </a>
      </div>

      <div className={styles.footer}>
        <Button
          variant={ButtonVariants.unstyled}
          onClick={openLogoutModal}
          type="button"
          disabled={logout.isLoading}
          isLoading={logout.isLoading}
          label="Logout"
        />
      </div>
    </div>
  )
}
