import styles from './index.module.scss'

export function SimpleButtonLoader() {
  return (
    <div className={styles.ldsellipsis}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
