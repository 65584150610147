import Tippy from '@tippyjs/react'
import { ReactElement } from 'react'
import { FormattedText } from '../FormattedText'
import { Icon, IconNames, IconSize } from '../icon'
import styles from './tooltip.module.scss'

export type ToolTipProps = {
  content: string | ReactElement
  children?: ReactElement
  proBadge?: boolean
}

export function Tooltip({ content, proBadge = false }: ToolTipProps) {
  return (
    <Tippy className={styles.tooltip} content={<FormattedText id={content} />}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button className={styles.iconWrapper} type="button">
        <Icon size={IconSize.xs} color="var(--color-secondary)" name={IconNames.infoCircle} />
        {proBadge && <div className={styles.badge}>premium</div>}
      </button>
    </Tippy>
  )
}

Tooltip.displayName = 'Tooltip'
