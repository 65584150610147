import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Loader } from 'src/core/ds/loader'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { usePins } from 'src/entities/pins/usePins'
import { useRouter } from 'src/hooks/router.hook'
import { isServerErrorShouldBeHandled } from 'src/hooks/serverErrors.hook'
import { useAnalytics } from 'src/hooks/useAnalytics'

export function WithAnonymousInitialData() {
  console.log('loading anon')

  return <Outlet />
}

export function WithInitialData() {
  // load everything and user data
  console.log('loading app')
  const { data: currentAccount, error, isLoading: isLoadingCurrentAccount } = useCurrentAccount()
  usePins()
  const analytics = useAnalytics()
  const { generateErrorLink } = useRouter()

  const isLoading = isLoadingCurrentAccount

  useEffect(() => {
    if (currentAccount) {
      analytics.identify(currentAccount)
    }
  }, [currentAccount?.email])

  if (isLoading) {
    return (
      <div style={{ position: 'fixed', width: '100%', paddingTop: '30vh' }}>
        <Loader isBig />
      </div>
    )
  }

  if (isServerErrorShouldBeHandled(error)) {
    return <Navigate to={generateErrorLink(error)} />
  }

  if (!currentAccount) {
    return <Navigate to="/login" />
  }

  // redirect ot onboarding page if no important
  // data presented
  // const isOnOnboardingPage = location.pathname === '/onboarding'

  // if (!isOnOnboardingPage && userModels?.length === 0) {
  //   return <Navigate to="/onboarding" />
  // }

  return <Outlet />
}
