import { pushNotification } from 'src/services/notifications/notificationService'
import { copyToClipboard } from 'src/utils/strings'

export const onTextCopy = (text: string) => {
  copyToClipboard(
    text,
    pushNotification({
      type: 'success',
      title: 'Copied to clipboard',
    }),
  )
}
