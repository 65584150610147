import styles from './index.module.scss'

export function NotFoundScreen() {
  return (
    <div className={styles.wrapper}>
      <div>
        <h1>404</h1>
        <p>Nothing was found.</p>
        <a href="/">Go back home</a>
      </div>
    </div>
  )
}
