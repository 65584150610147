import { sendGAEvent } from 'src/hooks/useAnalytics'
import { useUIStore } from 'src/services/store/UIStore'
import { useCurrentAccount } from '../account/useCurrentAccount'
import { isFeatureAvailable } from './plans'

type UseFeatureProps = {
  featureName: string
  onFeatureAvailable?: any
  onFeatureBlocked?: any
}

export const useSubscription = () => {
  const { data: currentAccount } = useCurrentAccount()
  const currentPlan = currentAccount?.sub_plan || 'free'
  const setLastTriedPremiumFeature = useUIStore((state) => state.setLastTriedPremiumFeature)
  const setIsPwaywallOpen = useUIStore((state) => state.setIsPwaywallOpen)

  const tryUseFeature = ({ featureName, onFeatureAvailable, onFeatureBlocked }: UseFeatureProps) => {
    const canUseFeature = isFeatureAvailable(currentPlan, featureName)

    if (!canUseFeature) {
      setLastTriedPremiumFeature(featureName)
      setIsPwaywallOpen(true)
      sendGAEvent('subscription_suggest', { featureName })

      return onFeatureBlocked?.()
    }

    return onFeatureAvailable?.()
  }

  const isEligibleToUseFeature = (featureName) => isFeatureAvailable(currentPlan, featureName)

  return {
    isPremiumUser: currentAccount?.sub_plan !== null,
    tryUseFeature,
    isEligibleToUseFeature,
  }
}
