/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { apiClient } from 'src/api/api-client'
import { useModelApi, UseModelApiProps, UseModelApiResult } from 'src/api/core'
import { QUERY_KEY_PROMPTS_BY_SLUG } from './queryKeys'

export const useGetPromptBySlug = (props?: UseModelApiProps & { slug?: string }): UseModelApiResult<any> => {
  const client = apiClient.tasks()

  const query = useModelApi({
    ...props,
    id: 'promptsBySlug',
    enabled: !!props?.slug,
    queryKey: [QUERY_KEY_PROMPTS_BY_SLUG, props?.slug] as unknown as string,
    queryFn: () => client.getTaskBySlug(props?.slug),
  })

  return {
    ...query,
  }
}
