export const aiResponseStringHandler = (data?: string) => {
  if (data === undefined) return ''

  if (data === '') {
    return '\n'
  }

  return data
}

export const scrollToDiv = () => {
  const element = document.getElementById('scrollable')

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
