import { apiClient } from 'src/api/api-client'
import { useInfiniteCollectionApi } from 'src/api/core'
import { QUERY_KEY_PROMPTS } from './queryKeys'

export const usePrompts = (props) => {
  const client = apiClient.tasks()

  const query = useInfiniteCollectionApi({
    ...props,
    // eslint-disable-next-line
    // @ts-ignore
    id: 'prompts',
    queryKey: [QUERY_KEY_PROMPTS, props?.query],
    // refetchInterval: props?.refetchEnabled ? 10000 : 9999999999,
    enabled: props?.enabled,
    // eslint-disable-next-line
    // @ts-ignore
    queryFn: ({ pageParam = 1 }) => client.searchByQuery({ page: pageParam, query: props?.query }),
  })

  return { ...query }
}
