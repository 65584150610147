/* eslint-disable react/no-unescaped-entities */
// import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { Icon, IconNames } from 'src/core/ds/icon'
import { Tabs } from 'src/core/ds/tabs'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { sendGAEvent } from 'src/hooks/useAnalytics'
// import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

const tabs = ['Monthly', 'Semi-annual', 'Annual']

function Tier0() {
  return (
    <div className={styles.advantages}>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        100 Signup credits
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        GPT, Claude, Gemini Pro, Stable Diffusion, Pixart, Flux.1 and more
      </p>
      <p>
        <Icon name={IconNames.close} color="var(--color-red)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        History search
      </p>
      <p>
        <Icon name={IconNames.close} color="var(--color-red)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize assistants
      </p>

      <p>
        <Icon name={IconNames.close} color="var(--color-red)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Parallel requests (upcoming)
        <sup>1</sup>
      </p>
      <p>
        <Icon name={IconNames.close} color="var(--color-red)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize history (upcoming)
      </p>
    </div>
  )
}

export function Tier1({ creds }) {
  return (
    <div className={styles.advantages}>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {creds} credits included
        <sup>*</sup>
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        GPT, Claude, Gemini Pro, <br /> Stable Diffusion, Pixart, Flux.1 and more
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        History search
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize assistants
      </p>

      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Parallel requests (upcoming)
        <sup>1</sup>
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize history (upcoming)
      </p>
    </div>
  )
}

export function Tier2({ creds }) {
  return (
    <div className={styles.advantages}>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {creds} credits included
        <sup>*</sup>
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        GPT, Claude, Gemini Pro, <br /> Stable Diffusion, Pixart, Flux.1 and more
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        History search
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize assistants
      </p>

      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Parallel requests (upcoming)
        <sup>1</sup>
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize history (upcoming)
      </p>
    </div>
  )
}

function Tier3({ creds }) {
  return (
    <div className={styles.advantages}>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        {creds} credits included
        <sup>*</sup>
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        GPT, Claude, Gemini Pro, <br /> Stable Diffusion, Pixart, Flux.1 and more
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        History search
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize assistants
      </p>

      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Parallel requests (upcoming)
        <sup>1</sup>
      </p>
      <p>
        <Icon name={IconNames.checkFill} color="var(--color-primary)" />
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Pin and organize history (upcoming)
      </p>
    </div>
  )
}

const prices = {
  Monthly: [
    {
      title: 'Start',
      price: '$9.90',
      type: 'start',
      oldprice: '$29',
      credits: 750,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      charsInputLimit: '4,000',
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier1 creds="5,200" />,
    },
    {
      title: 'PRO',
      price: '$19.90',
      type: 'pro',
      oldprice: '$29',
      credits: 750,
      textLimit: 60,
      imageLimit: 20,
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      charsInputLimit: '48,000',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier2 creds="11,000" />,
    },
    {
      title: 'Business',
      price: '$29.90',
      type: 'business',
      oldprice: '$29',
      credits: 750,
      textLimit: 400,
      imageLimit: 125,
      charsInputLimit: '48,000',
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier3 creds="18,000" />,
    },
    {
      title: 'Free (pay as you go)',
      price: '$0',
      type: 'free',
      oldprice: '$29',
      credits: 750,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      charsInputLimit: '4,000',
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier0 />,
    },
  ],
  'Semi-annual': [
    {
      title: 'Start',
      price: '$8.31',
      type: 'start',
      oldprice: '$29',
      credits: 750,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      charsInputLimit: '4,000',
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier1 creds="31,200" />,
    },
    {
      title: 'PRO',
      price: '$16.65',
      type: 'pro',
      oldprice: '$29',
      credits: 750,
      textLimit: 60,
      imageLimit: 20,
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      charsInputLimit: '48,000',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier2 creds="66,000" />,
    },
    {
      title: 'Business',
      price: '$25',
      type: 'business',
      oldprice: '$29',
      credits: 750,
      textLimit: 400,
      imageLimit: 125,
      charsInputLimit: '48,000',
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier3 creds="108,000" />,
    },
    {
      title: 'Free (pay as you go)',
      price: '$0',
      type: 'free',
      oldprice: '$29',
      credits: 750,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      charsInputLimit: '4,000',
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier0 />,
    },
  ],
  Annual: [
    {
      title: 'Start',
      price: '$7.50',
      type: 'start',
      oldprice: '$29',
      credits: 750,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      charsInputLimit: '4,000',
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier1 creds="62,400" />,
    },
    {
      title: 'PRO',
      price: '$14.99',
      type: 'pro',
      oldprice: '$29',
      credits: 750,
      textLimit: 60,
      imageLimit: 20,
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      charsInputLimit: '48,000',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier2 creds="132,000" />,
    },
    {
      title: 'Business',
      price: '$22.50',
      type: 'business',
      oldprice: '$29',
      credits: 750,
      textLimit: 400,
      imageLimit: 125,
      charsInputLimit: '48,000',
      label:
        'The initial input is 6000. The maximum input is 48,000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier3 creds="216,000" />,
    },
    {
      title: 'Free (pay as you go)',
      price: '$0',
      type: 'free',
      oldprice: '$29',
      credits: 750,
      textLimit: 20,
      imageLimit: 7,
      label:
        'The initial input is 2000. The maximum input is 4000. Any characters beyond the initial input will be counted as additional requests.',
      period: 1,
      charsInputLimit: '4,000',
      url: 'https://pay.zentask.ai/b/eVaaI33kzcgGbn2bIV',
      jsx: <Tier0 />,
    },
  ],
}

const getPeriodInInt = (period) => {
  if (period === 'Annual') {
    return 12
  }

  if (period === 'Semi-annual') {
    return 6
  }

  return 1
}

export function PricesPicogen({ showFree = true, shouldShowUrl = false, hideHeader = false }) {
  console.log(showFree)
  const token = (localStorage && localStorage.getItem('token')) || ''
  const [currentIndex] = useState(2)

  const { data: currentUser } = useCurrentAccount()
  // const updateUIStore = useUIStore((state) => state.updateUIStore)

  const currentSubPlan = currentUser?.sub_plan || null

  return (
    <section id="prices" className={styles.prices}>
      {!hideHeader && (
        <div className={styles.hero}>
          <h2>Prices</h2>
          <p className={styles.description}>Unleash the power of AI with a Premium account.</p>
        </div>
      )}

      <div className={styles.pricesWrapper}>
        <div className={styles.pricesBox}>
          <Tabs
            // context={
            //   // eslint-disable-next-line
            //   <div>
            //     <span className={styles.discount}>1m free</span>
            //     <span className={styles.bonus}>2m free</span>
            //   </div>
            // }
            currentIndex={currentIndex}
            tabs={tabs}
          >
            {tabs.map((tab, index) => (
              <div key={index} className={styles.tab}>
                <div className={styles.list}>
                  {prices[tab].map((priceItem) => (
                    <div
                      key={priceItem.title}
                      className={`${styles.priceItem} ${
                        currentSubPlan === priceItem.type ||
                        (!currentSubPlan && priceItem.type === 'free' && shouldShowUrl)
                          ? styles.activeSub
                          : undefined
                      }`}
                    >
                      <div className={styles.dealWrapper}>
                        {/* <div className={styles.deal}>First Purchase Offer. Save 20%.</div> */}
                        {/* <div className={styles.oldPrice}>{priceItem.oldprice}</div> */}
                      </div>
                      <h4>
                        {priceItem.price}
                        <span> / month</span>
                      </h4>

                      <h3>
                        {priceItem.title}
                        {priceItem.title === 'PRO' ? <span>Best Deal 🏆</span> : null}
                      </h3>

                      {priceItem.jsx}

                      {currentUser && (
                        <div className={styles.actionWrapper}>
                          <form
                            action={`${config.baseAPIUrl}/account/pay/${priceItem.type}?value=${getPeriodInInt(tab)}`}
                            method="post"
                          >
                            <input hidden name="TKN" value={token} />

                            {currentSubPlan === priceItem.type || (!currentSubPlan && priceItem.type === 'free') ? (
                              <Button
                                disabled
                                variant={ButtonVariants.secondary}
                                type="submit"
                                label="Your current plan"
                              />
                            ) : (
                              <Button
                                onClick={() => {
                                  sendGAEvent('account_subscribe')
                                }}
                                type="submit"
                                label="Get started"
                                icon={IconNames.arrowRight}
                              />
                            )}
                          </form>
                          {priceItem.type !== 'free' && <p className={styles.cancel}>Cancel anytime</p>}
                        </div>
                      )}

                      {!currentUser && (
                        <div className={styles.actionWrapper}>
                          {currentSubPlan === priceItem.type ||
                          (!currentSubPlan && priceItem.type === 'free') ? null : (
                            <Link to="/login">
                              <Button
                                onClick={() => {
                                  sendGAEvent('account_subscribe')
                                  // updateUIStore('isAuthModalOpen', true)
                                }}
                                type="button"
                                label="Get started"
                                icon={IconNames.arrowRight}
                              />
                            </Link>
                          )}

                          {priceItem.type !== 'free' && <p className={styles.cancel}>Cancel anytime</p>}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Tabs>
        </div>
      </div>

      {shouldShowUrl && (
        <div style={{ marginTop: '1.5rem', textAlign: 'center' }}>
          <Link to="/terms-of-use">Terms of use</Link>
        </div>
      )}

      {shouldShowUrl && (
        <div className={styles.details}>
          <div className={styles.box}>
            * All credits have an expiration date and will be used before the account balance starts to be spent.
          </div>

          {/* <div className={styles.box}>
            Concurrency – In the context of APIs, a 'concurrent request' means multiple requests sent at the same time,
            or overlapping in time, rather than one after another. For instance, if you send a second request before
            getting the response to the first, you have two concurrent requests.
          </div>

          <div className={styles.box}>
            "Request Cost" is an additional charge for each handled API request, which is essential for our server
            operation and service quality. It's a small investment for reliable, efficient, and top-notch service.
          </div>

          <div className={styles.box}>
            "Unlimited enqueue" feature lets you add as many requests as you need to the queue, with no upper limit.
          </div> */}
        </div>
      )}
    </section>
  )
}
