import { useMutation } from 'react-query'
import { apiClient } from 'src/api/api-client'
import { createQueryKey, useModelApi, UseModelApiProps } from 'src/api/core'
import { ServerErrorEnum } from 'src/api/types'
import { useServerNotification } from 'src/hooks/serverNotification.hook'
import { queryClient } from 'src/queries/reactQueryClient'
import { pushNotification } from 'src/services/notifications/notificationService'
import { QUERY_KEY_PINS } from './queryKeys'

export const usePins = (props?: UseModelApiProps<any> & { isEnabled: boolean }) => {
  const client = apiClient.pins()
  const { notify } = useServerNotification()

  const query = useModelApi({
    ...props,
    id: 'pins',
    enabled: props?.isEnabled,
    queryKey: QUERY_KEY_PINS,
    queryFn: client.getAllPinnedTasks,
  })

  const addPinMutation = useMutation(client.addPin, {
    onError: (error: [ServerErrorEnum, null]) => {
      notify(error as any)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(createQueryKey({ queryKey: QUERY_KEY_PINS, role: 'collection', scope: 'default' }))
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_PINS })
      pushNotification({
        type: 'success',
        title: 'Assistant pinned',
      })
      // fireEvent(ANALYTICS_EVENTS.CreateSnap)
      // window?.fbq('track', 'CreateSnap')
    },
  })

  const removePinMutation = useMutation(client.removePin, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEY_PINS })
    },
  })

  return {
    ...query,
    addPin: {
      ...addPinMutation,
    },
    removePin: {
      ...removePinMutation,
    },
  }
}
