export const plansAndFeatures = {
  free: {
    pins: false,
    historySearch: false,
    parallelRequests: false,
    extraModels: false,
  },
  start: {
    pins: true,
    historySearch: true,
    parallelRequests: true,
    extraModels: true,
  },
  pro: {
    pins: true,
    historySearch: true,
    parallelRequests: true,
    extraModels: true,
  },
  business: {
    pins: true,
    historySearch: true,
    parallelRequests: true,
    extraModels: true,
  },
}

export const isFeatureAvailable = (plan: string, feature: string) => plansAndFeatures[plan][feature] || false
