import { apiClient } from 'src/api/api-client'
import { useModelApi } from 'src/api/core'
import { QUERY_KEY_HISTORY } from './queryKeys'

export const useHistory = (props) => {
  const client = apiClient.history()

  const query = useModelApi({
    ...props,
    id: 'current',
    refetchOnMount: true,
    enabled: props.isEnabled,
    queryKey: QUERY_KEY_HISTORY,
    queryFn: () => client.searchByQuery(props.query),
  })

  return {
    ...query,
  }
}
