import { apiClient } from 'src/api/api-client'
import { useModelApi, UseModelApiProps } from 'src/api/core'
import { User } from '../account'
import { useIsSubscriber } from '../account/useIsSubscriber'
import { QUERY_KEY_MANAGE_PLAN_LINK } from './queryKeys'

export const useManagePlan = (props?: UseModelApiProps<User>) => {
  const client = apiClient.subscription()
  const { isSubscriber } = useIsSubscriber()

  return useModelApi({
    ...props,
    id: 'plan',
    enabled: isSubscriber,
    queryKey: QUERY_KEY_MANAGE_PLAN_LINK,
    queryFn: client.getSubscriptionLink,
  })
}
