import { Scroller } from 'src/components/Scroller/Scroller'
import { TestimonialItem } from 'src/components/TestimonialItem/TestimonialItem'
import { testimonials } from 'src/config/testimonials'
import styles from '../index.module.scss'

export function Testimonials() {
  return (
    <div className={styles.testimonial}>
      <h2>Join our empowered community</h2>
      <p>Zentask has helped thousands of people boost their productivity and improve their quality of life.</p>
      <Scroller direction="right" speed="slow">
        {testimonials.map((item) => (
          <TestimonialItem key={item.id} data={item} />
        ))}
      </Scroller>
    </div>
  )
}
