import 'react-modern-drawer/dist/index.css'

import { useEffect } from 'react'
import Drawer from 'react-modern-drawer'
import { ESCAPE } from 'src/constants/keyCodes'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { useMedia } from 'src/hooks/useMedia'
import styles from './index.module.scss'

export function DrawerWrapper({ title = 'title', isOpen, width = '250px', onClose, children }) {
  const mediaWidth = useMedia()
  useEffect(() => {
    window.document.addEventListener('keydown', onKeyPress)

    return () => {
      window.document.removeEventListener('keydown', onKeyPress)
    }
    // eslint-disable-next-line
  }, [title])

  const onKeyPress = (e: KeyboardEvent) => {
    switch (e.keyCode) {
      // close
      case ESCAPE:
        onCustomClose()
        break

      default:
        break
    }
  }

  const onCustomClose = () => {
    if (document.body) {
      document.body.removeAttribute('style')
    }

    onClose?.()
  }

  const isMobile = mediaWidth < 768

  return (
    // eslint-disable-next-line
    <Drawer
      size={isMobile ? '100%' : width}
      lockBackgroundScroll={isOpen || !!isMobile}
      className={styles.drawer}
      open={isOpen}
      duration={150}
      direction="right"
      onClose={onCustomClose}
    >
      <div className={styles.wrapper}>
        <header>
          <h2>{title}</h2>
          <Button
            iconColor="var(--color-secondary)"
            variant={ButtonVariants.unstyled}
            onClick={onCustomClose}
            type="button"
            label=""
            tooltip="Close"
            icon={IconNames.close}
          />
        </header>
        <div className={styles.body}>{children}</div>
      </div>
    </Drawer>
  )
}
