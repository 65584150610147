import create from 'zustand'

export const useUIStore = create<any>((set) => ({
  isOpenPromptsSearch: false,
  setIsOpenPromptsSearch: (isOpen) => set({ isOpenPromptsSearch: isOpen }),
  isOpenHistorySearch: false,
  setIsOpenHistorySearch: (isOpen) => set({ isOpenHistorySearch: isOpen }),
  isOpenUserarea: false,
  setIsOpenUserarea: (isOpen) => set({ isOpenUserarea: isOpen }),
  isOpenTopupModal: false,
  setIsOpenTopupModal: (isOpen) => set({ isOpenTopupModal: isOpen }),
  lastTriedPremiumFeature: null,
  setLastTriedPremiumFeature: (feature) => set({ lastTriedPremiumFeature: feature }),
  isPaywallOpen: false,
  setIsPwaywallOpen: (isOpen) => set({ isPaywallOpen: isOpen }),
}))
