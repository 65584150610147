import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiClient } from 'src/api/api-client'
import { Button } from 'src/core/ds/button'
import { ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { Loader } from 'src/core/ds/loader'
import { useChatStore } from 'src/entities/chat/chatStore'
import { usePins } from 'src/entities/pins/usePins'
import { usePrompts } from 'src/entities/prompts/usePrompts'
import { useSubscription } from 'src/entities/subscription/useSubscription'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { useDebounce } from 'src/hooks/useDebounce'
import { useUIStore } from 'src/services/store/UIStore'
import { Prompt } from './components/Prompt'
import styles from './index.module.scss'

export function PromptsSearch({ isEnabled }) {
  const { taskSlug } = useParams()
  const [searchInput, setSearchInput] = useState('')
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [searchResults, setSearchResults] = useState<any>(null)
  const debouncedValue = useDebounce(searchInput, 500)
  const setIsOpenPromptsSearch = useUIStore((state) => state.setIsOpenPromptsSearch)
  const selectedTask = useChatStore((store) => store.selectedTask)
  const { data: pinsList, addPin, removePin } = usePins({ isEnabled })
  const pinIds = pinsList?.map((pin) => pin.id)

  const setSelectedTask = () => {
    setIsOpenPromptsSearch(false)

    if (document.body) {
      document.body.removeAttribute('style')
    }
  }

  // eslint-disable-next-line
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = usePrompts({
    enabled: isEnabled,
    query: '',
  })
  const client = apiClient.tasks()

  const prompts: any = data?.pages
    ?.flatMap((page) => page?.items ?? page)
    .filter((el) => el !== undefined && !(pinIds || []).includes(el?.id))

  const onReset = () => {
    setSearchInput('')
    setSearchResults(null)
  }

  const { tryUseFeature } = useSubscription()

  const onAddPin = async (id) => {
    sendGAEvent('click_pin_add')
    tryUseFeature({ featureName: 'pins', onFeatureAvailable: () => addPin.mutateAsync(id) })
    // await addPin.mutateAsync(id)
  }

  const onRemovePin = async (id) => {
    sendGAEvent('click_pin_remove')
    await removePin.mutateAsync(id)
  }

  useEffect(() => {
    if (debouncedValue.length < 3) {
      setSearchResults(null)

      return
    }

    setIsLoadingSearch(true)

    client
      .searchByQuery({ query: debouncedValue })
      .then((response: any) => {
        if (response?.length > 0) {
          setSearchResults(response)
        }

        if (response?.length === 0) {
          setSearchResults([])
        }

        setIsLoadingSearch(false)
      })
      .catch((err) => {
        setIsLoadingSearch(false)
        console.log('error', err)
      })
  }, [debouncedValue, client])

  const isSearchView = debouncedValue.length > 2
  // console.log(fetchNextPage, hasNextPage)

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Input
          icon={IconNames.search}
          type="text"
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search assistants"
          value={searchInput}
          autofocus
          isLoading={isLoadingSearch}
          action={
            // eslint-disable-next-line
            searchInput.length > 2 ? (
              <Button
                onClick={onReset}
                variant={ButtonVariants.unstyled}
                iconColor="var(--color-secondary)"
                type="button"
                icon={IconNames.close}
              />
            ) : null
          }
        />
      </div>

      {!isSearchView && (
        <>
          <div className={styles.results}>
            <h2>Pinned assistants</h2>
            <div className={styles.resultsList}>
              {/* eslint-disable-next-line */}
              {((pinsList as any) || []).map((item: any) => (
                <Prompt
                  onUse={setSelectedTask}
                  onAddPin={onAddPin}
                  onRemovePin={onRemovePin}
                  isPinned={pinIds?.includes(item.id)}
                  key={item.id}
                  isUsed={item?.slug === taskSlug}
                  item={item}
                  isLoading={addPin.isLoading || removePin.isLoading}
                />
              ))}
              {!pinsList || pinsList?.length === 0 ? (
                <div className={styles.emptyPinsList}>No assistants pinned</div>
              ) : null}
            </div>
          </div>
          <div className={styles.results}>
            <h2>Assistants</h2>
            <div className={styles.resultsList}>
              {/* eslint-disable-next-line */}
              {((prompts as any) || []).map((item: any) => (
                <Prompt
                  onAddPin={onAddPin}
                  onUse={setSelectedTask}
                  onRemovePin={onRemovePin}
                  isPinned={pinIds?.includes(item.id)}
                  key={item?.id}
                  item={item}
                  isUsed={item?.slug === taskSlug}
                  isLoading={addPin.isLoading || removePin.isLoading}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {isSearchView && (
        <div className={styles.results}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <h2>Found assistants: {searchResults?.length}</h2>
          <div className={styles.resultsList}>
            {/* eslint-disable-next-line */}
            {((searchResults as any) || []).map((item: any) => (
              <Prompt
                onAddPin={onAddPin}
                onUse={setSelectedTask}
                onRemovePin={onRemovePin}
                isPinned={pinIds?.includes(item.id)}
                isUsed={item.id === selectedTask?.id}
                key={item.id}
                item={item}
                isLoading={addPin.isLoading || removePin.isLoading}
              />
            ))}
          </div>
        </div>
      )}

      {hasNextPage && (
        <div className={styles.action}>
          <Button isLoading={isFetchingNextPage} type="button" onClick={fetchNextPage} label="common.loadMore" />
        </div>
      )}
    </div>
  )
}
