import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Markdown } from 'src/components/Markdown'
import { ResponseSkeleton } from 'src/components/skeletons/ResponseSkeleton'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { IconNames } from 'src/core/ds/icon'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { useChatStore } from 'src/entities/chat/chatStore'
import { useAIChat } from 'src/entities/chat/useAIChat'
import { ZenLayout } from 'src/layouts/ZenLayout'
import { ChatInput } from 'src/widgets/ChatInput'
import styles from './index.module.scss'
import { ScrollToHashElement } from './ScrollToHashElement'
import { onTextCopy } from './useNotifications'

export function ZenScreen() {
  const { historyId } = useParams()
  const {
    messages,
    currentTask,
    isLoadingCurrentAssitant,
    sendMessage,
    resetChatMessages,
    currentAiResponse,
    isConnected,
    status,
    contextId,
  } = useAIChat()

  const selectedTask = useChatStore((store) => store.selectedTaskId)

  const { data: currentUser } = useCurrentAccount()

  return (
    <ZenLayout>
      {currentTask && (
        <Helmet>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <title>{currentTask?.title} | Zentask.ai</title>
          <meta name="description" content={currentTask?.description} />
          <meta property="og:title" content={`${currentTask?.title} | Zentask.ai`} />
          <meta property="og:description" content={currentTask?.description} />
        </Helmet>
      )}
      <div className={styles.content}>
        {messages.map((el, index) =>
          el.type === 'ai' ? (
            <div key={index} className={styles.contentWrapper}>
              <div className={styles.copyButton}>
                <Button
                  icon={IconNames.copy}
                  type="button"
                  iconColor="var(--color-secondary)"
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.unstyled}
                  tooltip="Copy output"
                  onClick={() => onTextCopy(el.content)}
                />
              </div>
              <div translate="no" id={el.id} className={styles.contentMD}>
                <Markdown markdown={el.content} />
              </div>
            </div>
          ) : (
            <div key={index} id={el.id} translate="no" className={styles.historySettingsContent}>
              <div className={styles.copyButton}>
                <Button
                  icon={IconNames.copy}
                  type="button"
                  iconColor="var(--color-secondary)"
                  size={ButtonSizes.xs}
                  variant={ButtonVariants.unstyled}
                  tooltip="Copy input"
                  onClick={() => onTextCopy(el.content)}
                />
              </div>
              <div className={styles.settingsItem}>{el.content}</div>
            </div>
          ),
        )}
        {status === 'processing' && !currentAiResponse && <ResponseSkeleton />}
        {!messages ||
          (messages?.length === 0 && (
            <div className={styles.assistantInfo}>
              <div className={styles.assistantInfoAi}>{currentTask?.ai?.name}</div>
              <div className={styles.assistantInfoTitle}>{currentTask?.title}</div>
              <div className={styles.assistantInfoDescription}>{currentTask?.description}</div>
              <div className={styles.contentWrapper}>
                <div translate="no" className={styles.contentMD}>
                  <Markdown markdown={currentTask?.info} />
                </div>
              </div>
            </div>
          ))}
        <div className={styles.contentWrapper}>
          <div translate="no" className={styles.contentMD}>
            <Markdown markdown={currentAiResponse} />
          </div>
        </div>
      </div>
      <div id="scrollable" />
      <ScrollToHashElement />

      <ChatInput
        isLoadingCurrentAssitant={isLoadingCurrentAssitant}
        contextId={contextId || historyId}
        resetChatMessages={resetChatMessages}
        isDisabled={!isConnected}
        currentTask={currentTask}
        sendMessage={sendMessage}
        hint={selectedTask.hint || 'Ask me anything'}
        currentUser={currentUser}
        status={status}
      />
    </ZenLayout>
  )
}
