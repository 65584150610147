import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { useFlow } from 'src/hooks/useFlow'
import { LoginEmail } from './steps/LoginEmail.flow'
import { LoginVerification } from './steps/LoginVerification.flow'

const loginFlow = ['email', 'verification']

const renderCurrentStep = ({ currentStep, props }: any) => {
  const { onCancelFlow, goBack, email, setEmail, goNext, onCompleteFlow } = props
  switch (currentStep) {
    case 'email':
      return <LoginEmail onCancelFlow={onCancelFlow} email={email} setEmail={setEmail} goNext={goNext} />
    case 'verification':
      return (
        <LoginVerification email={email} onCancelFlow={onCancelFlow} onCompleteFlow={onCompleteFlow} goBack={goBack} />
      )
    default:
      return null
  }
}

export function LoginScreen() {
  const [email, setEmail] = useState('')
  const { data: currentAccount } = useCurrentAccount()
  const { onCancelFlow, goBack, goNext, onCompleteFlow, currentStep } = useFlow({
    flowName: 'login-flow',
    navigationSequence: loginFlow,
    cancelUrlFallback: '/',
  })

  if (currentAccount) {
    return <Navigate to="/chat/talk-to-zentask" />
  }

  return renderCurrentStep({
    currentStep,
    props: { onCancelFlow, email, setEmail, goBack, goNext, onCompleteFlow },
  })
}
