import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { SimpleLoader } from 'src/core/ds/spinner'
import { sendGAEvent } from 'src/hooks/useAnalytics'
import { useUIStore } from 'src/services/store/UIStore'
import styles from './index.module.scss'

export function UserArea({ currentUser }) {
  const setIsOpenUserarea = useUIStore((state) => state.setIsOpenUserarea)

  if (!currentUser) return <SimpleLoader />

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => {
          sendGAEvent('click_open_account')
          setIsOpenUserarea(true)
        }}
        className={styles.box}
      >
        <Avatar
          size={AVATAR_SIZES.sm}
          url={
            currentUser.avatar_url || 'https://pbs.twimg.com/profile_images/1631938866218258432/QRnxbWuj_400x400.jpg'
          }
        />
      </div>
    </>
  )
}
