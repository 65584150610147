import { Helmet } from 'react-helmet'
import { Container } from 'src/core/ds/container'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { AppLayout } from 'src/layouts/AppLayout'
// eslint-disable-next-line import/no-cycle
import { Footer } from 'src/layouts/Footer'
import styles from './index.module.scss'
import { Blog } from './parts/Blog'
import { Comparison } from './parts/Comparison'
import { Faq } from './parts/Faq'
import { PricesNew } from './parts/PricesNew'
import { CustomFeatures } from './seoparts/CustomFeatures'
import { CustomHero } from './seoparts/CustomHero'
import { CustomTestimonials } from './seoparts/CustomTestimonials'
import { CustomUpsell } from './seoparts/CustomUpsell'

declare global {
  interface Window {
    cfToken: string
    plausible: any
  }
}

export function SEOLandingScreen({ data }: any) {
  const { data: currentUser } = useCurrentAccount()

  return (
    <AppLayout>
      <Helmet>
        <title>{data.title}</title>
        <meta name="description" content={data.subtitle} />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.subtitle} />
        <link rel="canonical" href={`https://snaptap.ai/${data?.urlSlug}`} />
      </Helmet>

      <div className={styles.surface}>
        <CustomHero data={data} currentUser={currentUser} />
      </div>
      <Container>
        <CustomFeatures data={data} />

        <Comparison />

        <section className={styles.cost}>
          <PricesNew isInnerBlock={false} />
        </section>

        <CustomTestimonials key={data.urlSlug} testimonials={data.testimonials} />

        <Blog />

        <Faq />

        <CustomUpsell data={data} />

        <div className={styles.article}>{data.article}</div>
      </Container>
      <Footer />
    </AppLayout>
  )
}
