/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react'
import { config } from 'src/config/config'
import { Button } from 'src/core/ds/button'
import { ButtonSizes } from 'src/core/ds/button/types'
// import { Icon, IconNames } from 'src/core/ds/icon'
import { Input } from 'src/core/ds/input'
import { sendGAEvent } from 'src/hooks/useAnalytics'
// import { Tooltip } from 'src/core/ds/tooltip/Tooltip'
import { pushNotification } from 'src/services/notifications/notificationService'
import styles from './index.module.scss'

const IMAGE_PRICE = 0.002

export function Topup() {
  const [amount, setAmount] = useState('10')
  const token = (localStorage && localStorage.getItem('token')) || ''

  const onSubmitPayment = async (event) => {
    sendGAEvent('account_deposit')

    if (+amount < 10 || Number.isNaN(+amount)) {
      event.preventDefault()
      pushNotification({
        title: 'Error',
        subtitle: 'Minimum amount is $10',
        type: 'error',
      })

      return false
    }

    return false
  }

  return (
    <div className={styles.container}>
      <div className={styles.credits}>
        <h3>Buy credits</h3>
        {/* <Tooltip content="lol">
          <Icon name={IconNames.alertCircle} />
        </Tooltip> */}
        {/* <span>~{Number(22 / 10)} images</span> */}
      </div>

      <div className={styles.buy}>
        <form action={`${config.baseAPIUrl}/account/pay?value=${amount}`} method="post">
          <input hidden name="TKN" value={token} />

          <Input
            action={<Button type="submit" onClick={onSubmitPayment} label="Buy" size={ButtonSizes.xs} />}
            value={amount}
            prefix="$"
            label="Amount"
            description="You will be redirected to Stripe"
            tooltipProps={{
              label: 'Min payment is $10. Steps in $10. The amount is rounded up (ex. $12 -> $20).',
            }}
            name="credits"
            onChange={(event) => {
              setAmount(event.target.value)
            }}
            placeholder="Enter amount"
          />
        </form>
      </div>

      <div className={styles.buyDetails}>
        <div>
          <span>
            You will get {Number.isNaN(+amount / IMAGE_PRICE) ? 0 : +amount / IMAGE_PRICE}
            &nbsp;credits
          </span>
          {/* <p>~{Number.isNaN(+amount / IMAGE_PRICE) ? 0 : Number(+amount / IMAGE_PRICE / 10)} images</p> */}
        </div>
      </div>
    </div>
  )
}
