import { MetaTitle } from 'src/components/MetaTitle'
import { Container } from 'src/core/ds/container'
import { Footer } from 'src/layouts/Footer'
import { ZenLayout } from 'src/layouts/ZenLayout'
import { Blog } from '../landing/parts/Blog'
import { Faq } from '../landing/parts/Faq'
import { Testimonials } from '../landing/parts/Testimonials'
import { Upsell } from '../landing/parts/Upsell'
// import { PhotoListSimple } from 'src/widgets/PhotoListSimple/PhotoListSimple.widget'
import styles from './index.module.scss'
import { PricesPicogen } from './PricesPicogen'

export function PricesScreen() {
  return (
    <ZenLayout>
      <MetaTitle title="Prices" />
      <Container>
        <div className={styles.wrapper}>
          <PricesPicogen shouldShowUrl />

          <Testimonials />

          <Blog />

          <Faq />

          <Upsell />
        </div>
      </Container>
      <Footer />
    </ZenLayout>
  )
}
