import { Link } from 'react-router-dom'
import { Button } from 'src/core/ds/button'
import styles from './index.module.scss'

export function Prompt({ item, isUsed, isLoading, onUse, isPinned, onAddPin, onRemovePin }) {
  return (
    <Link onClick={onUse} to={`/chat/${item.slug}`} className={`${styles.item} ${isUsed ? styles.active : undefined}`}>
      <div className={styles.ai}>{item?.ai?.name}</div>
      <div className={styles.title}>{item.title}</div>
      <div className={styles.description}>{item.description}</div>
      <div className={styles.actions}>
        {!isPinned && (
          <Button
            isLoading={isLoading}
            type="button"
            onClick={(e) => {
              e.preventDefault()
              onAddPin(item.id)
            }}
            tooltip="Add assistant to pins"
            tooltipOnLeft
            label="Pin"
          />
        )}
        {isPinned && (
          <Button
            isLoading={isLoading}
            type="button"
            tooltipOnLeft
            onClick={(e) => {
              e.preventDefault()
              onRemovePin(item.id)
            }}
            tooltip="Unpin this assistant"
            label="Unpin"
          />
        )}
      </div>
    </Link>
  )
}
