import { SimpleButtonLoader } from '../buttonSpinner'
import { FormattedText } from '../FormattedText'
import { Icon, IconNames, IconSize } from '../icon'
import { Tooltip } from '../tooltip/Tooltip'
import styles from './index.module.scss'
import { InputSizes } from './types'

interface InputProps {
  value: string | null
  placeholder?: string
  isDisabled?: boolean
  type?: string
  inputSize?: InputSizes | any
  isFullWidth?: boolean
  onChange?: any
  onClick?: any
  onFocus?: any
  onBlur?: any
  prefix?: string
  description?: string
  icon?: IconNames
  stablePrefix?: string
  label?: string
  id?: string
  action?: any
  subValue?: string
  error?: string | null
  isLoading?: boolean
  isRequired?: boolean
  autofocus?: boolean
  tooltipProps?: {
    label: string
    showPremium?: boolean
  }
}

export function Input({
  type = 'text',
  inputSize = InputSizes.m,
  value,
  prefix,
  onFocus,
  onClick,
  isFullWidth = false,
  placeholder,
  isLoading = false,
  isDisabled = false,
  icon,
  label,
  onChange,
  onBlur,
  stablePrefix,
  id = 'input',
  isRequired = false,
  action,
  description,
  subValue,
  autofocus = false,
  error,
  tooltipProps,
  ...rest
}: InputProps & React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <>
      {label && (
        <label className={styles.label} htmlFor={id}>
          <FormattedText id={label} />
          {isRequired && '*'}
          {tooltipProps && <Tooltip content={tooltipProps?.label} proBadge={tooltipProps.showPremium} />}
          {error && <Icon size={IconSize.xs} name={IconNames.alertFill} color="var(--color-red)" />}
        </label>
      )}
      {description && (
        <div className={styles.description}>
          <FormattedText id={description} />
        </div>
      )}

      <div className={styles.inputContainer}>
        {isLoading && (
          <span className={styles.icon}>
            <SimpleButtonLoader />
          </span>
        )}
        {stablePrefix && <div className={styles.stablePrefix}>{stablePrefix}</div>}
        {prefix && <span className={`${styles.prefixContainer} ${styles[`prefix-size-${inputSize}`]}`}>{prefix}</span>}
        {(action || subValue) && (
          <div className={styles.subContainer}>
            <span>{subValue}</span>
            {action}
          </div>
        )}
        {icon && (
          <span className={styles.iconContainer}>
            <Icon name={icon} color="var(--color-secondary)" />
          </span>
        )}
        <input
          className={`${styles.input} ${styles[inputSize]} ${isFullWidth ? styles.full : ''} ${
            prefix ? styles.prefix : ''
          } ${error ? styles.error : ''} ${icon ? styles.prefixIcon : ''}  ${
            stablePrefix ? styles.stablePrefixInput : ''
          }`}
          type={type}
          id={id}
          name={id}
          // eslint-disable-next-line
          autoFocus={autofocus}
          autoComplete="off"
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={isDisabled}
          // eslint-disable-next-line
          {...rest}
        />
      </div>
      {error && (
        <div className={styles.errorField}>
          <Icon size={IconSize.xs} name={IconNames.alertFill} color="var(--color-red)" />
          <span>{error}</span>
        </div>
      )}
    </>
  )
}
