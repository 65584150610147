export const blogData = {
  '1': {
    title: 'How to Create Stunning Instagram Photos Without Leaving Home: The Power of AI',
    description:
      'Unlock the secrets of AI to create Instagram-worthy photos at home. No travel needed – just creativity and your smartphone!',
    image: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/c19c756d-ea00-4efc-bf03-8f6b2ddac800/medium',
    date: '16.01.2024',
    link: '/blog/create-stunning-instagram-photos-without-leaving-home-the-power-of-ai',
    slug: 'create-stunning-instagram-photos-without-leaving-home-the-power-of-ai',
  },
  '2': {
    title: 'Unleashing the Power of Pixels: Maximizing Engagement on Instagram with AI-Enhanced Imagery',
    description:
      'Discover how to boost your Instagram engagement by leveraging AI-enhanced imagery. Learn tips, tricks, and strategies to captivate your audience and make your feed stand out in a sea of photos.',
    image: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/9ff25cd5-3550-4f6a-7d47-587bbaff1f00/medium',
    date: '17.01.2024',
    link: '/blog/unleashing-the-power-of-pixels-maximizing-engagement-on-instagram-with-ai-enhanced-imagery',
    slug: 'unleashing-the-power-of-pixels-maximizing-engagement-on-instagram-with-ai-enhanced-imagery',
  },
  '3': {
    title: 'How to Craft the Perfect Photo Description for Snaptap.AI',
    description: `Whether you're new to Instagram or looking to spice up your feed, Snaptap.AI, powered by the Stable Diffusion XL 1.0 model, offers an exciting way to transform your ideas into visually captivating images without needing any technical know-how.`,
    image: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/80c9bb39-7ed1-47d5-d05a-42459c6ccd00/medium',
    date: '17.02.2024',
    link: '/blog/how-to-craft-the-perfect-photo-description-for-snaptap-ai',
    slug: 'how-to-craft-the-perfect-photo-description-for-snaptap-ai',
  },
  '4': {
    title: 'Unleashing Creativity on a Budget: The AI Revolution in Visual Content',
    description:
      'Explore how AI is transforming the realm of professional photography, making cost-effective visual content a reality. Dive into the world of accessible, high-quality photoshoots powered by artificial intelligence.',
    image: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/80c9bb39-7ed1-47d5-d05a-42459c6ccd00/medium',
    date: '17.01.2024',
    link: '/blog/unleashing-creativity-on-a-budget-the-ai-revolution-in-visual-content',
    slug: 'unleashing-creativity-on-a-budget-the-ai-revolution-in-visual-content',
  },
  '5': {
    title: 'Unlock the Magic of AI: Transform Your Photos into Stunning AI Masterpieces',
    description:
      'Discover how to create breathtaking images through AI, turn your photos into AI art, and elevate your Instagram feed with the power of artificial intelligence. Dive into the future of photography with AI.',
    image: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/80c9bb39-7ed1-47d5-d05a-42459c6ccd00/medium',
    date: '25.02.2024',
    link: '/blog/unlock-the-magic-transform-photos-into-ai-masterpieces',
    slug: 'unlock-the-magic-transform-photos-into-ai-masterpieces',
  },
  '6': {
    title: 'Revolutionize Your Instagram with AI: Create Stunning Photos That Look Like You',
    description:
      'Discover the future of Instagram photography with our cutting-edge SaaS platform. Transform your selfies and portraits into captivating images using the power of AI. Perfect for influencers, brands, and anyone looking to enhance their Instagram feed with unique, personalized photos.',
    image: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/6cbfff99-b0ee-4fab-f718-faaea7caa700/medium',
    date: '25.02.2024',
    link: '/blog/revolutionize-instagram-ai-create-stunning-photos',
    slug: 'revolutionize-instagram-ai-create-stunning-photos',
  },
  '7': {
    title: 'Revolutionize Your Instagram with AI: Create Stunning Photos That Look Just Like You',
    description:
      'Discover how to transform your Instagram feed using artificial intelligence. Create captivating, personalized photos with AI that mirrors your likeness perfectly. Elevate your social media game today.',
    image: 'https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/6cbfff99-b0ee-4fab-f718-faaea7caa700/medium',
    date: '25.02.2024',
    link: '/blog/revolutionize-instagram-with-ai-personalized-photos',
    slug: 'revolutionize-instagram-with-ai-personalized-photos',
  },
}
