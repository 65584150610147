/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from 'src/core/ds/avatar'
import { AVATAR_SIZES } from 'src/core/ds/avatar/consts'
import { Button } from 'src/core/ds/button'
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/types'
import { Container } from 'src/core/ds/container'
import { IconNames } from 'src/core/ds/icon'
import { useCurrentAccount } from 'src/entities/account/useCurrentAccount'
import { useMedia } from 'src/hooks/useMedia'
import { Logo } from '../Logo'
import styles from './index.module.scss'

export function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { data: currentUser } = useCurrentAccount()

  const width = useMedia()

  if (width <= 820) {
    return (
      <>
        {/* <div data-content="promo" className={styles.promo}>
          <div className={styles.promoWrapper}>
            <Link to="/influencer-program">

              Share information about Zentask on social media and earn up to <span>$2,500</span> per post.
            </Link>
          </div>
        </div> */}

        <header className={styles.header}>
          {isDrawerOpen && (
            <div className={styles.drawer}>
              <Container>
                <div className={styles.drawerAction}>
                  <Button
                    icon={IconNames.close}
                    variant={ButtonVariants.unstyled}
                    size={ButtonSizes.xs}
                    type="button"
                    iconColor="var(--color-text)"
                    label=""
                    onClick={() => setIsDrawerOpen(false)}
                  />
                </div>

                <nav className={styles.drawerNav}>
                  <ul>
                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="/#prompts">
                        Features
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="/#prices">
                        Prices
                      </a>
                    </li>

                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="/#faq">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setIsDrawerOpen(false)} href="/#footer">
                        Subscribe
                      </a>
                    </li>
                  </ul>
                </nav>
              </Container>
            </div>
          )}
          <Container>
            <div className={styles.wrapper}>
              <Link title="Zentask" className={styles.logo} to="/">
                <Logo />
              </Link>

              {!currentUser && (
                <div className={styles.mobileAction}>
                  <div className={styles.mobileActionButtons}>
                    <Link to="/login">
                      <Button size={ButtonSizes.xs} variant={ButtonVariants.secondary} type="button" label="Sign in" />
                    </Link>
                    <Link to="/login">
                      <Button size={ButtonSizes.xs} type="button" label="Sign up" />
                    </Link>
                  </div>
                  <div className={styles.hamburger}>
                    <Button
                      icon={IconNames.hamburger}
                      variant={ButtonVariants.unstyled}
                      size={ButtonSizes.xs}
                      type="button"
                      onClick={() => setIsDrawerOpen(true)}
                      iconColor="var(--color-text)"
                      label=""
                    />
                  </div>
                </div>
              )}
              {currentUser && (
                <div>
                  <Link className={styles.auth} to="/chat/talk-to-zentask">
                    Go to chat
                    <Avatar size={AVATAR_SIZES.sm} url={currentUser.avatar_url} />
                  </Link>
                </div>
              )}
            </div>
          </Container>
        </header>
      </>
    )
  }

  return (
    <>
      {/* <div data-content="promo" className={styles.promo}>
        <div className={styles.promoWrapper}>
          <Link to="/influencer-program">

            Share information about Zentask on social media and earn up to <span>$2,500</span> per post.
          </Link>
        </div>
      </div> */}

      <header className={styles.header}>
        <Container>
          <div className={styles.wrapper}>
            <nav>
              <Link title="Zentask" className={styles.logo} to="/">
                <Logo />
              </Link>
            </nav>

            <ul className={styles.nav}>
              <li>
                <a href="/#prompts">Features</a>
              </li>
              <li>
                <a href="/#prices">Prices</a>
              </li>
              <li>
                <a href="/#faq">FAQ</a>
              </li>
              <li>
                <a href="/#footer">Subscribe</a>
              </li>
            </ul>

            {!currentUser && (
              <div className={styles.auth}>
                {/* <LanguageSwitcher /> */}
                <Link to="/login">
                  <Button size={ButtonSizes.xs} variant={ButtonVariants.secondary} type="button" label="Sign in" />
                </Link>
                <Link to="/login">
                  <Button size={ButtonSizes.xs} type="button" label="Sign up" />
                </Link>
              </div>
            )}
            {currentUser && (
              <div className={styles.auth}>
                {/* <LanguageSwitcher /> */}
                <Link className={styles.auth} to="/chat/talk-to-zentask">
                  Open chat
                  <Avatar size={AVATAR_SIZES.sm} url={currentUser.avatar_url} />
                </Link>
              </div>
            )}
          </div>
        </Container>
      </header>
    </>
  )
}
